// hooks/usePapercut/usePapercutHover.js
import { useState, useCallback } from 'react';

export const usePapercutHover = () => {
  const [hoveredSegment, setHoveredSegment] = useState(null);
  const [hoveredWord, setHoveredWord] = useState(null);

  const handleWordHover = useCallback((segmentId, wordId) => {
    const timerId = setTimeout(() => {
      setHoveredWord({ segmentId, wordId });
    }, 500);
    return () => clearTimeout(timerId);
  }, []);

  return {
    hoveredSegment,
    hoveredWord,
    setHoveredSegment,
    setHoveredWord,
    handleWordHover
  };
};