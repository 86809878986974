import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField
} from '@mui/material';
import {
  ChevronRight,
  ExpandMore,
  MoreVert as MoreVertIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import { useFileSystem, FileType } from '../../contexts/FileSystemContext';
import FileTypeIcon from './FileTypeIcon';

const FileItem = ({ file, depth = 0, onDragOver, isExpanded, onToggleExpand, index }) => {


  const { 
    deleteItem, 
    renameItem, 
    selectedItems,
    handleSelection, 
  } = useFileSystem();
  
  const [anchorEl, setAnchorEl] = useState(null);
  const [isRenaming, setIsRenaming] = useState(file.isNew);
  const [newName, setNewName] = useState(file.name);
  const [isDraggingOver, setIsDraggingOver] = useState(false);
  const ref = useRef(null);

  const isSelected = selectedItems.includes(file.id);


  // Helper function to remove file extension
  function getDisplayName(filename) {
    if (file.type === 'folder') return filename;
    return filename.replace(/\.[^/.]+$/, "");
  }

  // Helper function to preserve file extension during rename
  function getFullName(displayName) {
    if (file.type === 'folder') return displayName;
    const extension = file.name.match(/\.[^/.]+$/)?.[0] || '';
    return `${displayName}${extension}`;
  }

  const handleMenuOpen = (event) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleRename = () => {
    setIsRenaming(true);
    handleMenuClose();
  };

  const handleRenameSubmit = () => {
    const fullName = getFullName(newName);
    renameItem(file.id, fullName);
    setIsRenaming(false);
  };
  const handleDelete = () => {
    deleteItem(file.id);
    handleMenuClose();
  };

  const handleDragStart = (e) => {
    e.dataTransfer.setData('text/plain', file.id);
    // If the dragged item is not in the current selection, clear the selection and select only this item
    if (!selectedItems.includes(file.id)) {
      handleSelection(file.id, e);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    
    if (file.type === 'folder') {
      setIsDraggingOver(true);
      onDragOver(file.id, 'inside');
    } else {
      const rect = ref.current.getBoundingClientRect();
      const y = e.clientY - rect.top;
      onDragOver(file.id, y < rect.height / 2 ? 'before' : 'after');
    }
  };

  const handleDragLeave = () => {
    setIsDraggingOver(false);
  }
  const handleClick = (event) => {
    event.stopPropagation();
    
    if (file.type === FileType.FOLDER) {
      onToggleExpand(file.id);
      return;
    }
  
    handleSelection(file.id, event);
  };

  useEffect(() => {
    if (file.isNew) {
      setIsRenaming(true);
    }
  }, [file.isNew]);

  return (
    <Box
      ref={ref}
      draggable
      onDragStart={handleDragStart}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onClick={handleClick}
      sx={{
        display: 'flex',
        alignItems: 'center',
        pl: 1 + depth * 2,
        py: 0.5,
        cursor: 'pointer',
        bgcolor: isSelected 
          ? 'action.selected' 
          : isDraggingOver 
          ? 'action.hover' 
          : 'transparent',
        '&:hover': {
          bgcolor: isSelected ? 'action.selected' : 'action.hover',
        },
        color: isSelected ? 'primary.main' : 'text.primary',
      }}
    >
      {file.type === 'folder' && (
        <IconButton
          size="small"
          onClick={(e) => {
            e.stopPropagation();
            onToggleExpand(file.id);
          }}
          sx={{ p: 0.5, mr: 0.5 }}
        >
          {isExpanded ? <ExpandMore fontSize="small" /> : <ChevronRight fontSize="small" />}
        </IconButton>
      )}
      <FileTypeIcon type={file.type} />
      <Box sx={{ ml: 1, flexGrow: 1 }}>
        {isRenaming ? (
          <TextField
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            onBlur={handleRenameSubmit}
            onKeyPress={(e) => e.key === 'Enter' && handleRenameSubmit()}
            size="small"
            autoFocus
            sx={{ fontSize: '0.875rem' }}
            onClick={(e) => e.stopPropagation()} // Prevent select when clicking textfield
          />
        ) : (
          <Typography 
            variant="body2"
            sx={{
              fontWeight: isSelected ? 500 : 400,
            }}
          >
            {getDisplayName(file.name)}
          </Typography>
        )}
      </Box>
      <IconButton 
        size="small" 
        onClick={(e) => {
          e.stopPropagation();
          handleMenuOpen(e);
        }}
      >
        <MoreVertIcon fontSize="small" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={handleRename}>
          <EditIcon fontSize="small" sx={{ mr: 1 }} />
          Rename
        </MenuItem>
        <MenuItem onClick={handleDelete}>
          <DeleteIcon fontSize="small" sx={{ mr: 1 }} />
          Delete
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default FileItem;