import React from 'react';
import { Typography, Box } from '@mui/material';
import { alpha } from '@mui/material/styles';

export const SegmentWord = React.memo(({ 
  word, 
  segment,
  content,
  segmentIndex,
  isSelected,
  isInSelection,
  onWordClick,
  onWordHover,
  onSelectionStart,
  onSelectionUpdate,
  highlightStyle,
  cursorPosition,
  isFocused
}) => {
  const handleMouseDown = (e) => {
    e.preventDefault();
    onSelectionStart(segment.id, word.id);
  };

  const handleMouseMove = (e) => {
    if (e.buttons === 1) { // Left mouse button is pressed
      e.preventDefault();
      onSelectionUpdate(segment.id, word.id);
    }
  };
  const handleClick = (e) => {
    e.preventDefault();
    const rect = e.currentTarget.getBoundingClientRect();
    const clickedInFirstHalf = e.clientX - rect.left < rect.width / 2;
    
    if (clickedInFirstHalf) {
      // Clicking in first half means cursor should go BEFORE this word
      onWordClick(segment.id, word.id, true);
    } else {
      // Clicking in second half means cursor should go AFTER this word
      // Use the next word's ID if available, otherwise stay at current word
      const nextWord = segment.words[word.index + 1];
      if (nextWord) {
        onWordClick(segment.id, nextWord.id, true);
      } else {
        onWordClick(segment.id, word.id, false);
      }
    }
  };
  // Check if this word has the cursor
  const hasCursor = cursorPosition?.segmentId === segment.id && 
                   cursorPosition?.wordId === word.id;
  
  return (
    <Typography
      component="span"
      variant="body2"
      onClick={handleClick}
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseEnter={() => onWordHover(segment.id, word.id)}
      onMouseLeave={() => onWordHover(null, null)}
      data-word-id={word.id}
      sx={{
        display: 'inline-block',
        cursor: 'text',
        px: 0.5,
        py: 0.25,
        borderRadius: 1,
        position: 'relative',
        backgroundColor: theme => {
          if (isInSelection) {
            return isFocused ? theme.palette.action.selected : alpha(theme.palette.action.selected, 0.3);
          }
          if (isSelected) {
            return isFocused ? theme.palette.action.focus : alpha(theme.palette.action.focus, 0.3);
          }
          return 'transparent';
        },
        '&:hover': {
          backgroundColor: theme => {
            if (isInSelection) {
              return isFocused ? theme.palette.action.selected : alpha(theme.palette.action.selected, 0.3);
            }
            if (isSelected) {
              return isFocused ? theme.palette.action.focus : alpha(theme.palette.action.focus, 0.3);
            }
            return theme.palette.action.hover;
          }
        },
        transition: 'background-color 0.2s',
        userSelect: 'text',
        ...highlightStyle,
        ...(highlightStyle && highlightStyle.backgroundColor && {
          '&:hover': {
            backgroundColor: theme => isFocused ? 
              highlightStyle.backgroundColor : 
              alpha(highlightStyle.backgroundColor, 0.3)
          }
        })
      }}
    >
      {word.text}
      {hasCursor && (
        <Box
          sx={{
            position: 'absolute',
            ...(word.index === 0 && cursorPosition?.isStartOfWord 
              ? { left: 0 } 
              : { right: 0 }),
            top: 0,
            width: 4, // Thicker line for more visibility
            height: '100%',
            backgroundColor: theme => theme.palette.primary.main, // Fully opaque color
            opacity: 1, // Always fully visible
            boxShadow: '0 0 4px rgba(0,0,0,0.3)', // Subtle shadow for contrast
            // Remove the blinking animation for a stable cursor line
            animation: 'none',
            transition: 'background-color 0.2s, opacity 0.2s'
          }}
        />
      )}
    </Typography>
  );
});

export default SegmentWord;