// src/components/Pages/SignUpPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  TextField,
  Button,
  Typography,
  Alert,
  CircularProgress,
  useTheme
} from '@mui/material';

const SignUpPage = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    inviteCode: ''
  });

  const handleChange = (e) => {
    setFormData((prev) => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('[SignUpPage] handleSubmit called with data:', formData);
    setError('');
    setLoading(true);

    if (formData.password !== formData.confirmPassword) {
      console.log('[SignUpPage] Passwords do not match');
      setError('Passwords do not match');
      setLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_URL}/api/register`;
    console.log('[SignUpPage] Attempting registration at URL:', url);

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email: formData.email,
          password: formData.password,
          inviteCode: formData.inviteCode
        }),
      });

      console.log('[SignUpPage] Response status:', response.status);

      // Log the raw text to see what we received before parsing JSON
      const responseText = await response.text();
      console.log('[SignUpPage] Raw response text:', responseText);

      let data;
      try {
        data = JSON.parse(responseText);
      } catch (jsonError) {
        console.error('[SignUpPage] Failed to parse JSON:', jsonError);
        throw new Error('Invalid JSON response');
      }

      console.log('[SignUpPage] Parsed JSON data:', data);

      if (!response.ok) {
        console.error('[SignUpPage] Registration failed with error:', data.error);
        throw new Error(data.error || 'Registration failed');
      }

      // Store token and user info in localStorage
      console.log('[SignUpPage] Registration succeeded, storing token and user');
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      // Redirect to home page on success
      console.log('[SignUpPage] Redirecting to home page');
      navigate('/');
    } catch (error) {
      console.error('[SignUpPage] Catch error:', error.message);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  console.log('[SignUpPage] Rendering with REACT_APP_API_URL:', process.env.REACT_APP_API_URL);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: `linear-gradient(to bottom right, ${theme.palette.primary.light}, ${theme.palette.background.default})`,
        p: 2
      }}
    >
      <Card
        sx={{
          width: '100%',
          maxWidth: 420,
          borderRadius: 2,
          boxShadow: 3,
          overflow: 'hidden',
        }}
      >
        <CardContent sx={{ p: 4 }}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontWeight: 'bold' }}>
            Create Account
          </Typography>
          <Typography variant="body2" color="text.secondary" align="center" sx={{ mb: 3 }}>
            Join us and start exploring today.
          </Typography>

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              required
              margin="normal"
              variant="outlined"
            />

            <TextField
              fullWidth
              label="Password"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              required
              margin="normal"
              variant="outlined"
              helperText="Must be at least 8 characters"
            />

            <TextField
              fullWidth
              label="Confirm Password"
              name="confirmPassword"
              type="password"
              value={formData.confirmPassword}
              onChange={handleChange}
              required
              margin="normal"
              variant="outlined"
            />

            <TextField
              fullWidth
              label="Invite Code"
              name="inviteCode"
              value={formData.inviteCode}
              onChange={handleChange}
              required
              margin="normal"
              variant="outlined"
            />

            <Button
              fullWidth
              variant="contained"
              type="submit"
              disabled={loading}
              sx={{
                mt: 3,
                py: 1.2,
                fontWeight: 'bold',
                backgroundColor: theme.palette.primary.main,
                '&:hover': {
                  backgroundColor: theme.palette.primary.dark
                }
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Create Account'}
            </Button>
          </form>
        </CardContent>
      </Card>

      <Typography
        variant="body2"
        color="text.secondary"
        align="center"
        sx={{ mt: 2 }}
      >
        Already have an account?{' '}
        <Button
          variant="text"
          color="primary"
          onClick={() => navigate('/login')}
          sx={{
            textTransform: 'none',
            fontWeight: 'bold',
            padding: 0,
            minWidth: 'auto'
          }}
        >
          Sign in
        </Button>
      </Typography>
    </Box>
  );
};

export default SignUpPage;
