import React, { createContext, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';

export const ClipboardContext = createContext(undefined);

export const ClipboardProvider = ({ children }) => {
  const [clipboardContent, setClipboardContent] = useState(null);
  const [clipboardSource, setClipboardSource] = useState(null);
  const [lastUpdateTimestamp, setLastUpdateTimestamp] = useState(null);

  const storeInClipboard = useCallback((content, source) => {
    console.log(`Storing content from ${source}:`, content);
    
    // Transform content to unified format
    const transformedContent = content.map(segment => ({
      fileId: segment.fileId || segment.sourceReference?.fileId,
      fileName: segment.fileName || 'Unknown',
      speaker: segment.speaker,
      words: segment.words.map(word => ({
        id: word.id || uuidv4(),
        text: word.text || word.word,
        start: word.startTime || word.start,
        end: word.endTime || word.end,
        globalIndex: word.index || word.globalIndex
      })),
      startTime: segment.startTime || segment.words[0]?.startTime,
      endTime: segment.endTime || segment.words[segment.words.length - 1]?.endTime,
      globalIndex: segment.globalIndex || segment.sourceReference?.segmentId
    }));

    setClipboardContent(transformedContent);
    setClipboardSource(source);
    setLastUpdateTimestamp(Date.now());

    // Update system clipboard
    const plainText = transformedContent
      .map(segment => segment.words.map(w => w.text).join(' '))
      .join('\n');
    navigator.clipboard.writeText(plainText);

    console.log('✅ Content stored successfully:', {
      content: transformedContent,
      source,
      timestamp: Date.now()
    });
  }, []);

  const getFromClipboard = useCallback(() => {
    console.log('Retrieving clipboard content:', {
      content: clipboardContent,
      source: clipboardSource,
      timestamp: lastUpdateTimestamp
    });
    return {
      content: clipboardContent,
      source: clipboardSource,
      timestamp: lastUpdateTimestamp
    };
  }, [clipboardContent, clipboardSource, lastUpdateTimestamp]);

  return (
    <ClipboardContext.Provider value={{
      storeInClipboard,
      getFromClipboard
    }}>
      {children}
    </ClipboardContext.Provider>
  );
};

export default ClipboardProvider;