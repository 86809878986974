import React, { useState } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { CssBaseline, Box, Snackbar, Alert } from '@mui/material';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import EditorLayout from './components/Layout/EditorLayout';
import BinViewerSection from './components/Viewers/BinViewerSection';
import PapercutViewerSection from './components/Viewers/PapercutViewer/PapercutViewerSection';
import ChatBot from './components/Chatbot/ChatBot';
import SearchPage from './components/Pages/SearchPage';
import NotificationsPage from './components/Pages/NotificationsPage';
import ProfilePage from './components/Pages/ProfilePage';
import SettingsPage from './components/Pages/SettingsPage';
import BillingPage from './components/Pages/BillingPage';
import SupportPage from './components/Pages/SupportPage';
import { AuthProvider } from './contexts/AuthContext';
import ProtectedApp from './components/Layout/ProtectedApp';
import SignUpPage from './components/Pages/SignUpPage';

console.log('[App.js] App component loaded');

const App = () => {
  console.log('[App.js] App function rendering');
  const [themeMode, setThemeMode] = useState('dark');
  const [notification, setNotification] = useState(null);
  const [chatMessages, setChatMessages] = useState([]);
  const [selectedBinClip, setSelectedBinClip] = useState(null);
  const [transcripts, setTranscripts] = useState(new Map());
  const [currentView, setCurrentView] = useState('editor');

  const theme = createTheme({
    palette: {
      mode: themeMode,
      primary: { main: '#0ea5e9' },
      background: {
        default: themeMode === 'dark' ? '#121212' : '#ffffff',
        paper: themeMode === 'dark' ? '#1e1e1e' : '#f5f5f5',
      },
    },
  });

  const handleThemeChange = () => {
    console.log('[App.js] handleThemeChange triggered');
    setThemeMode((mode) => (mode === 'dark' ? 'light' : 'dark'));
  };

  const timelineState = {
    clips: [],
    totalDuration: 0,
    settings: {}
  };

  const handleFileUpload = async (file) => {
    console.log('[App.js] handleFileUpload called with file:', file.name);
    try {
      if (file.name.endsWith('.json')) {
        const text = await file.text();
        const transcriptData = JSON.parse(text);
        setTranscripts(prev => new Map(prev).set(file.name, transcriptData));
        console.log('[App.js] File uploaded and transcriptData stored');
      }
    } catch (error) {
      console.error('[App.js] Error uploading file:', error);
      setNotification({ message: `Error uploading file: ${error.message}`, severity: 'error' });
    }
  };

  const renderContent = () => {
    console.log('[App.js] renderContent called with currentView:', currentView);
    switch (currentView) {
      case 'home':
      case 'editor':
        return (
          <EditorLayout>
            <Box sx={{
              display: 'flex',
              height: 'calc(100vh - 48px)',
              overflow: 'hidden',
              gap: 2,
              p: 2
            }}>
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <BinViewerSection />
              </Box>
              <Box sx={{ flex: 1, minWidth: 0 }}>
                <PapercutViewerSection transcriptData={transcripts} />
              </Box>
            </Box>
          </EditorLayout>
        );
      case 'search':
        return <SearchPage />;
      case 'notifications':
        return <NotificationsPage />;
      case 'profile':
        return <ProfilePage />;
      case 'settings':
        return <SettingsPage themeMode={themeMode} onThemeChange={handleThemeChange} />;
      case 'billing':
        return <BillingPage />;
      case 'support':
        return <SupportPage />;
      default:
        return null;
    }
  };

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        {console.log('[App.js] Rendering Router and Routes')}
        <Router>
          <AuthProvider>
            <Routes>
              {console.log('[App.js] Defining Routes')}
              {/* Public Route */}
              <Route path="/signup" element={<SignUpPage />} />

              {/* Protected Routes */}
              <Route
                path="/*"
                element={
                  <ProtectedApp
                    themeMode={themeMode}
                    onThemeChange={handleThemeChange}
                    currentView={currentView}
                    onViewChange={setCurrentView}
                    mediaFiles={[]}
                    selectedBinClip={selectedBinClip}
                    onFileSelect={setSelectedBinClip}
                    onFileUpload={handleFileUpload}
                  >
                    {renderContent()}
                    <ChatBot
                      clips={[]}
                      messages={chatMessages}
                      onSendMessage={(msg) => {
                        console.log('[App.js] ChatBot onSendMessage:', msg);
                        setChatMessages(prev => [...prev, msg]);
                      }}
                      selectedBinClip={selectedBinClip}
                      transcriptData={selectedBinClip ? transcripts.get(selectedBinClip.name) : null}
                      timelineState={timelineState}
                      timelineRows={[]}
                      setTimelineRows={() => {}}
                      onClipsChange={() => {}}
                    />
                  </ProtectedApp>
                }
              />
            </Routes>
          </AuthProvider>

          <Snackbar open={!!notification} autoHideDuration={6000} onClose={() => setNotification(null)}>
            {notification && (
              <Alert onClose={() => setNotification(null)} severity={notification.severity}>
                {notification.message}
              </Alert>
            )}
          </Snackbar>
        </Router>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
