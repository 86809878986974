import { useContext, useCallback } from 'react';
import { ClipboardContext } from '../contexts/ClipboardContext';

export const useClipboard = (source) => {
  const context = useContext(ClipboardContext);
  if (!context) {
    throw new Error('useClipboard must be used within a ClipboardProvider');
  }

  const { storeInClipboard, getFromClipboard } = context;

  const copy = useCallback((content) => {
    if (!content || content.length === 0) {
      console.log('❌ No content to copy');
      return false;
    }
    storeInClipboard(content, source);
    return true;
  }, [storeInClipboard, source]);

  const paste = useCallback((callback) => {
    const clipboardData = getFromClipboard();
    if (!clipboardData.content) {
      console.log('No content in clipboard');
      return false;
    }
    callback(clipboardData);
    return true;
  }, [getFromClipboard]);

  return { copy, paste };
};

export default useClipboard;