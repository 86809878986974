// hooks/usePapercut/index.js
import { usePapercutActions } from './usePapercutActions';
import { usePapercutState } from './usePapercutState';
import { usePapercutSelection } from './usePapercutSelection';
import { usePapercutKeyboard } from './usePapercutKeyboard';
import { usePapercutScroll } from './usePapercutScroll';
import { usePapercutHover } from './usePapercutHover';
import { useDragAndDrop } from './useDragAndDrop';
import { usePapercutHistory } from './usePapercutHistory';


// Export individual hooks
export {
  usePapercutActions,
  usePapercutState,
  usePapercutSelection,
  usePapercutKeyboard,
  usePapercutScroll,
  usePapercutHover,
  useDragAndDrop,
  usePapercutHistory,
};

// Main hook that combines core functionality
export function usePapercut() {
  const actions = usePapercutActions();
  const state = usePapercutState();

  return {
    ...actions,
    ...state
  };
}

// Combine selection and keyboard functionality
export function usePapercutEditor(content, updateCursorPosition) {
  const selection = usePapercutSelection(content, updateCursorPosition);
  const keyboard = usePapercutKeyboard({
    content,
    selection: selection.selection,
    handlers: selection.handlers,
    operations: usePapercutActions()
  });

  return {
    ...selection,
    handleKeyDown: keyboard
  };
}

// Combine UI interaction hooks
export function usePapercutUI(lastInsertedSegmentId, content, handleContentUpdate) {
  // Obtain scrolling and segment reference management
  const scroll = usePapercutScroll(lastInsertedSegmentId);
  const hover = usePapercutHover();

  // Pass segmentRefs from scroll into useDragAndDrop
  const dragAndDrop = useDragAndDrop(content, handleContentUpdate, new Set(), scroll.segmentRefs);

  const handleDragOver = (e, segment, position) => {
    dragAndDrop.handleDragOver(e, segment, position);
  };

  const handleDrop = (e, segment, position) => {
    dragAndDrop.handleDrop(e, segment, position);
  };

  return {
    ...scroll,
    ...hover,
    ...dragAndDrop,
    handleDragOver,
    handleDrop
  };
}
