// src/contexts/FileSystemContext.js
import React, { createContext, useContext, useState, useCallback, useMemo, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';

const FileSystemContext = createContext(undefined);

export const FileType = {
  FOLDER: 'folder',
  VIDEO: 'video',
  DOCX: 'docx',
  SRT: 'srt',
  JSON: 'json',
  UNKNOWN: 'unknown'
};

export const FileSystemProvider = ({ children, onError }) => {
  const [files, setFiles] = useState({});
  const [selectedItems, setSelectedItems] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({});
  const [errorState, setErrorState] = useState(null);
  const [lastSelectedItem, setLastSelectedItem] = useState(null);

  const token = localStorage.getItem('token');

  const headers = useMemo(() => {
    const h = {};
    if (token) {
      h['Authorization'] = `Bearer ${token}`;
    }
    return h;
  }, [token]);

  const getDirectoryItems = useCallback((parentId) => {
    return Object.values(files)
      .filter(file => file.parentId === parentId)
      .sort((a, b) => a.order - b.order);
  }, [files]);

  const determineFileType = (filename) => {
    const extension = filename.split('.').pop().toLowerCase();
    switch (extension) {
      case 'docx':
        return FileType.DOCX;
      case 'srt':
      case 'srtx':
        return FileType.SRT;
      case 'json':
        return FileType.JSON;
      case 'mp4':
      case 'mov':
      case 'avi':
      case 'mkv':
        return FileType.VIDEO;
      default:
        return FileType.UNKNOWN;
    }
  };

  // Load files from server on mount or when token changes
  useEffect(() => {
    const fetchFiles = async () => {
      if (!token) return; // no token, no fetch
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files`, {
          headers
        });
        if (!response.ok) {
          throw new Error(`Failed to fetch files: ${response.status}`);
        }
        const data = await response.json();
        // data is an array of file objects from server
        const fileMap = {};
        data.forEach(f => {
          fileMap[f.id] = {
            id: f.id,
            name: f.name,
            parentId: f.parent_id,
            order: f.order_index,
            type: f.file_type === 'folder' ? FileType.FOLDER : determineFileType(f.name),
            content: f.content ? JSON.stringify(f.content) : null
          };
        });
        setFiles(fileMap);
      } catch (error) {
        console.error('Error fetching files:', error);
        setErrorState(error.message);
        if (onError) onError(error.message);
      }
    };

    fetchFiles();
  }, [token, headers, onError]);

  const addFile = useCallback(async (file, parentId) => {
    const formData = new FormData();
    formData.append('file', file);
    if (parentId) {
      formData.append('parentId', parentId);
    }

    try {
      console.log('Uploading file to server:', file.name);

      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/upload`, {
        method: 'POST',
        headers, // include auth token
        body: formData
      });

      if (!response.ok) {
        throw new Error(`Upload failed: ${response.status}`);
      }

      const result = await response.json();
      console.log('Server response:', result);

      const newFile = result.file;
      setFiles(prev => ({
        ...prev,
        [newFile.id]: {
          id: newFile.id,
          name: newFile.name,
          type: newFile.file_type === 'folder' ? FileType.FOLDER : determineFileType(newFile.name),
          parentId: newFile.parent_id,
          order: newFile.order_index,
          content: newFile.content ? JSON.stringify(newFile.content) : null
        }
      }));

      return newFile.id;
    } catch (error) {
      console.error('Upload error:', error);
      setErrorState(`Upload failed: ${error.message}`);
      if (onError) {
        onError(error.message);
      }
    } finally {
      setUploadProgress(prev => {
        const newProgress = { ...prev };
        delete newProgress[file.name];
        return newProgress;
      });
    }
  }, [onError, headers]);

  const createFolder = useCallback(async (parentId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files`, {
        method: 'POST',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: 'New Folder', file_type: 'folder', parentId })
      });
      if (!response.ok) {
        throw new Error(`Failed to create folder: ${response.status}`);
      }
      const folder = await response.json();

      setFiles(prev => ({
        ...prev,
        [folder.id]: {
          id: folder.id,
          name: folder.name,
          type: FileType.FOLDER,
          parentId: folder.parent_id,
          order: folder.order_index,
          isNew: true
        }
      }));

      return folder.id;
    } catch (error) {
      console.error('Create folder error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [onError, headers]);

  const moveItem = useCallback(async (itemId, newParentId, newOrder = null) => {
    // Fetch current item
    const item = files[itemId];
    if (!item) return;

    // Prepare payload
    const payload = {};
    if (newParentId !== undefined) payload.parentId = newParentId;
    if (newOrder !== null) payload.order = newOrder;

    if (Object.keys(payload).length === 0) return; // nothing to update

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/${itemId}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        throw new Error(`Failed to move item: ${response.status}`);
      }
      const updatedItem = await response.json();

      setFiles(prev => ({
        ...prev,
        [updatedItem.id]: {
          ...prev[updatedItem.id],
          parentId: updatedItem.parent_id,
          order: updatedItem.order_index
        }
      }));
    } catch (error) {
      console.error('Move item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [files, onError, headers]);

  const deleteItem = useCallback(async (itemId) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/${itemId}`, {
        method: 'DELETE',
        headers
      });
      if (!response.ok) {
        throw new Error(`Failed to delete item: ${response.status}`);
      }

      // Remove item and descendants from local state
      setFiles(prev => {
        const newFiles = { ...prev };
        const itemsToDelete = new Set();

        const collectItems = (id) => {
          itemsToDelete.add(id);
          Object.values(newFiles)
            .filter(file => file.parentId === id)
            .forEach(file => collectItems(file.id));
        };

        collectItems(itemId);
        itemsToDelete.forEach(id => delete newFiles[id]);

        return newFiles;
      });
      setSelectedItems(prev => prev.filter(id => id !== itemId));
    } catch (error) {
      console.error('Delete item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [onError, headers]);

  const getTranscriptData = useCallback((fileIds) => {
    return fileIds
      .map(id => files[id])
      .filter(file => file && file.type !== 'folder' && file.content)
      .map(file => ({
        id: file.id,
        name: file.name,
        content: file.content
      }));
  }, [files]);

  const renameItem = useCallback(async (itemId, newName) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/files/${itemId}`, {
        method: 'PUT',
        headers: {
          ...headers,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ name: newName })
      });
      if (!response.ok) {
        throw new Error(`Failed to rename item: ${response.status}`);
      }

      const updatedItem = await response.json();
      setFiles(prev => ({
        ...prev,
        [updatedItem.id]: {
          ...prev[updatedItem.id],
          name: updatedItem.name,
          isNew: false
        }
      }));
    } catch (error) {
      console.error('Rename item error:', error);
      setErrorState(error.message);
      if (onError) onError(error.message);
    }
  }, [onError, headers]);

  const handleSelection = useCallback((fileId, event) => {
    if (event.metaKey || event.ctrlKey) {
      setSelectedItems(prev => {
        const newSelection = new Set(prev);
        if (newSelection.has(fileId)) {
          newSelection.delete(fileId);
        } else {
          newSelection.add(fileId);
        }
        return Array.from(newSelection);
      });
    } else if (event.shiftKey && lastSelectedItem) {
      const parentId = files[fileId].parentId;
      const items = getDirectoryItems(parentId);
      
      const currentIndex = items.findIndex(item => item.id === fileId);
      const lastIndex = items.findIndex(item => item.id === lastSelectedItem);
      
      if (currentIndex !== -1 && lastIndex !== -1) {
        const start = Math.min(currentIndex, lastIndex);
        const end = Math.max(currentIndex, lastIndex);
        
        const newSelection = items.slice(start, end + 1).map(item => item.id);
        
        setSelectedItems(prev => {
          const prevSet = new Set(prev);
          newSelection.forEach(id => prevSet.add(id));
          return Array.from(prevSet);
        });
      }
    } else {
      setSelectedItems([fileId]);
    }
    setLastSelectedItem(fileId);
  }, [files, getDirectoryItems, lastSelectedItem]);

  const hasContent = useCallback(() => {
    return Object.keys(files).length > 0;
  }, [files]);

  const value = useMemo(() => ({
    files,
    selectedItems,
    setSelectedItems,
    lastSelectedItem,
    uploadProgress,
    addFile,
    createFolder,
    moveItem,
    deleteItem,
    getDirectoryItems,
    setLastSelectedItem,
    handleSelection,
    getTranscriptData,
    renameItem,
    hasContent,
  }), [
    files,
    selectedItems,
    lastSelectedItem,
    uploadProgress,
    addFile,
    createFolder,
    moveItem,
    deleteItem,
    getDirectoryItems,
    getTranscriptData,
    renameItem,
    hasContent,
    handleSelection
  ]);
  
  return (
    <FileSystemContext.Provider value={value}>
      {children}
      {errorState && (
        <Snackbar
          open={!!errorState}
          autoHideDuration={6000}
          onClose={() => setErrorState(null)}
        >
          <Alert
            onClose={() => setErrorState(null)}
            severity="error"
          >
            {errorState}
          </Alert>
        </Snackbar>
      )}
    </FileSystemContext.Provider>
  );
};

export const useFileSystem = () => {
  const context = useContext(FileSystemContext);
  if (!context) {
    throw new Error('useFileSystem must be used within FileSystemProvider');
  }
  return context;
};
