import React, { createContext, useContext, useState, useCallback, useEffect, useMemo } from 'react';

const PapercutContext = createContext();

export function PapercutProvider({ children }) {
  const [papercuts, setPapercuts] = useState([]);
  const [activeTab, setActiveTab] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);
  const [lastInsertedSegmentId, setLastInsertedSegmentId] = useState(null);
  
  const token = typeof window !== 'undefined' ? localStorage.getItem('token') : null;

  // Use useMemo to ensure headers is stable across renders
  const headers = useMemo(() => {
    const h = { 'Content-Type': 'application/json' };
    if (token) {
      h['Authorization'] = `Bearer ${token}`;
    }
    return h;
  }, [token]);

  useEffect(() => {
    const fetchPapercut = async () => {
      if (!activeTab) return;
      
      // Fetch file metadata
      const fileRes = await fetch(`${process.env.REACT_APP_API_URL}/api/files/${activeTab}`, { headers });
      if (!fileRes.ok) return;
      const fileData = await fileRes.json();
      
      if (fileData.file_type !== 'papercut') return;
      
      // Fetch papercut content
      const pcRes = await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${activeTab}`, { headers });
      if (!pcRes.ok) return;
      const pcData = await pcRes.json();
      
      setPapercuts(prev => {
        const existing = prev.find(p => p.id === activeTab);
        const newPapercut = {
          ...fileData,
          content: pcData.content || [],
          created: fileData.created_at ? new Date(fileData.created_at) : new Date(),
          modified: fileData.updated_at ? new Date(fileData.updated_at) : new Date()
        };
        if (existing) {
          return prev.map(p => p.id === activeTab ? newPapercut : p);
        } else {
          return [...prev, newPapercut];
        }
      });
    };
    
    fetchPapercut();
  }, [activeTab, headers]);

  const updateCursorPosition = useCallback((newPosition) => {
    setCursorPosition(newPosition);
  }, []);

  const updatePapercutContent = useCallback(async (papercutId, newContent) => {
    setPapercuts(prev => prev.map(papercut => {
      if (papercut.id === papercutId) {
        return {
          ...papercut,
          content: newContent,
          modified: new Date()
        };
      }
      return papercut;
    }));
    
    await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${papercutId}`, {
      method: 'PUT',
      headers,
      body: JSON.stringify({ content: newContent })
    });
  }, [headers]);

  const addContentToPapercut = useCallback(async (papercutId, content) => {
    setPapercuts(prev => {
      const papercut = prev.find(p => p.id === papercutId);
      if (!papercut) return prev;

      const lastAddedSegment = content[content.length - 1];
      let newPapercut = papercut;
      if (lastAddedSegment) {
        setLastInsertedSegmentId(lastAddedSegment.id);
        const lastWord = lastAddedSegment.words[lastAddedSegment.words.length - 1];
        setCursorPosition({
          segmentId: lastAddedSegment.id,
          wordId: lastWord.id
        });
      }

      newPapercut = {
        ...papercut,
        content: [...papercut.content, ...content],
        modified: new Date()
      };
      
      return prev.map(p => p.id === papercutId ? newPapercut : p);
    });

    const papercut = papercuts.find(p => p.id === papercutId);
    if (papercut) {
      await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${papercutId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ content: [...papercut.content, ...content] })
      });
    }
  }, [headers, papercuts]);

  const insertContentToPapercut = useCallback(async (papercutId, newContent, position) => {
    setPapercuts(prev => prev.map(papercut => {
      if (papercut.id === papercutId) {
        const updatedContent = [
          ...papercut.content.slice(0, position),
          ...newContent,
          ...papercut.content.slice(position)
        ];
        return {
          ...papercut,
          content: updatedContent,
          modified: new Date()
        };
      }
      return papercut;
    }));
    
    const papercut = papercuts.find(p => p.id === papercutId);
    if (papercut) {
      await fetch(`${process.env.REACT_APP_API_URL}/api/papercuts/${papercutId}`, {
        method: 'PUT',
        headers,
        body: JSON.stringify({ content: [...papercut.content.slice(0, position), ...newContent, ...papercut.content.slice(position)] })
      });
    }
  }, [headers, papercuts]);

  const createNewPapercut = useCallback(async () => {
    const res = await fetch(`${process.env.REACT_APP_API_URL}/api/files`, {
      method: 'POST',
      headers,
      body: JSON.stringify({
        name: `Papercut ${papercuts.length + 1}`,
        file_type: 'papercut'
      })
    });
    if (!res.ok) {
      console.error('Failed to create papercut file');
      return null;
    }
    const newFile = await res.json();

    const newPapercut = {
      id: newFile.id,
      name: newFile.name,
      content: [],
      created: new Date(newFile.created_at),
      modified: new Date(newFile.updated_at)
    };

    setPapercuts(prev => [...prev, newPapercut]);
    setActiveTab(newPapercut.id); 
    return newPapercut.id;
  }, [headers, papercuts]);

  return (
    <PapercutContext.Provider value={{
      papercuts,
      activeTab,
      setActiveTab,
      cursorPosition,
      lastInsertedSegmentId,
      updateCursorPosition,
      updatePapercutContent,
      addContentToPapercut,
      insertContentToPapercut,
      createNewPapercut
    }}>
      {children}
    </PapercutContext.Provider>
  );
}

export const usePapercuts = () => {
  const context = useContext(PapercutContext);
  if (!context) {
    throw new Error('usePapercuts must be used within PapercutProvider');
  }
  return context;
};
