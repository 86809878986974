// hooks/usePapercut/usePapercutScroll.js
import { useState, useCallback, useRef, useEffect } from 'react';

export const usePapercutScroll = (lastInsertedSegmentId) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const segmentRefs = useRef({});
  const contentRef = useRef(null);

  const setSegmentRef = useCallback((element, segmentId) => {
    if (element) {
      segmentRefs.current[segmentId] = element;
      if (segmentId === lastInsertedSegmentId && !hasScrolled) {
        setTimeout(() => {
          const container = contentRef.current;
          if (!container) return;

          const elementRect = element.getBoundingClientRect();
          const containerRect = container.getBoundingClientRect();
          const elementTop = elementRect.top;
          const containerMiddle = containerRect.top + (containerRect.height / 2);
          const offset = elementTop - containerMiddle + (elementRect.height / 2);
          
          container.scrollBy({
            top: offset,
            behavior: 'smooth'
          });
          setHasScrolled(true);
        }, 100);
      }
    }
  }, [lastInsertedSegmentId, hasScrolled]);

  const handleScroll = useCallback(() => {
    if (!hasScrolled && lastInsertedSegmentId) {
      setHasScrolled(true);
    }
  }, [hasScrolled, lastInsertedSegmentId]);

  useEffect(() => {
    if (lastInsertedSegmentId) {
      setHasScrolled(false);
    }
  }, [lastInsertedSegmentId]);

  return {
    contentRef,
    segmentRefs,
    setSegmentRef,
    handleScroll
  };
};

export default usePapercutScroll;