import React, { useRef, useEffect, useState } from 'react';
import { Box } from '@mui/material';
import Segment from './Segment';

export const SegmentList = ({
  content,
  cursorPosition,
  selection,
  isWordInSelection,
  updateCursorPosition,
  onWordClick,
  onWordHover,
  onSelectionStart,
  onSelectionUpdate,
  onSegmentRef,
  getSpeakerColor,
  theme,
  draggedSegment,
  dropIndicator,
  springs,
  onDragStart,
  onDragEnd,
  onDragOver,
  onDrop,
  getHighlightStyle,
  onDeleteSegment,
  hoveredSegment,
  onSegmentHover,
  isFocused,
  containerRef // A ref to the parent scroll container or main container
}) => {
  const [relativeY, setRelativeY] = useState(null);
  const listRef = useRef(null);

  useEffect(() => {
    if (dropIndicator && dropIndicator.offsetY != null && dropIndicator.targetId === null) {
      // Compute relative position of the drop line
      if (containerRef?.current && listRef.current) {
        const containerRect = containerRef.current.getBoundingClientRect();
        // Convert viewport offsetY to container-relative Y
        const newRelativeY = dropIndicator.offsetY - containerRect.top;
        setRelativeY(newRelativeY);
      } else {
        // If no containerRef is provided and the line doesn't appear correctly,
        // you can try using listRef or adjust logic accordingly.
        if (listRef.current) {
          const listRect = listRef.current.getBoundingClientRect();
          const newRelativeY = dropIndicator.offsetY - listRect.top;
          setRelativeY(newRelativeY);
        } else {
          setRelativeY(null);
        }
      }
    } else {
      // No valid drop line to show
      setRelativeY(null);
    }
  }, [dropIndicator, containerRef]);

  return (
    <Box
      ref={listRef}
      sx={{
        position: 'relative'
      }}
    >
      {content.map((segment, index) => (
        <Segment
          key={segment.id}
          content={content}
          segmentIndex={index}
          ref={(el) => onSegmentRef(el, segment.id)}
          segment={segment}
          isSelected={cursorPosition?.segmentId === segment.id}
          theme={theme}
          getSpeakerColor={getSpeakerColor}
          cursorPosition={cursorPosition}
          onWordClick={onWordClick}
          onWordHover={onWordHover}
          onDeleteSegment={onDeleteSegment}
          onSelectionStart={onSelectionStart}
          onSelectionUpdate={onSelectionUpdate}
          isWordInSelection={isWordInSelection}
          updateCursorPosition={updateCursorPosition}
          draggedSegment={draggedSegment}
          dropIndicator={dropIndicator}
          style={springs?.[index]}
          onDragStart={onDragStart}
          onDragEnd={onDragEnd}
          onDragOver={(e) => onDragOver?.(e, segment, 'middle')}
          onDrop={(e) => onDrop?.(e, segment, 'middle')}
          getHighlightStyle={getHighlightStyle}
          isHovered={hoveredSegment === segment.id}
          onMouseEnter={() => onSegmentHover(segment.id)}
          onMouseLeave={() => onSegmentHover(null)}
          selection={selection}
          isFocused={isFocused}
        />
      ))}

      {relativeY != null && (
        <Box
          sx={{
            position: 'absolute',
            top: `${relativeY}px`,
            left: 0,
            right: 0,
            height: '2px',
            backgroundColor: 'red', // Use a distinct color or theme color
            pointerEvents: 'none',
            zIndex: 9999
          }}
        />
      )}
    </Box>
  );
};

export default SegmentList;
