// hooks/usePapercutActions.js
import { useCallback } from 'react';
import { usePapercuts } from '../../contexts/PapercutContext';
import { v4 as uuidv4 } from 'uuid';

export function usePapercutActions() {
  const { 
    addContentToPapercut, 
    cursorPosition,
    updateCursorPosition,
    updatePapercutContent,
    papercuts
  } = usePapercuts();

  const validateAndResyncCursor = useCallback((content, cursorPosition) => {
    if (!cursorPosition || !content?.length) return null;
    
    // Try to find the current segment
    const segmentIndex = content.findIndex(s => s.id === cursorPosition.segmentId);
    
    if (segmentIndex === -1) {
      console.log('Lost segment reference, attempting resync');
      // Segment not found - try to find closest valid position
      const lastSegment = content[content.length - 1];
      if (!lastSegment?.words?.length) return null;
      
      return {
        segmentId: lastSegment.id,
        wordId: lastSegment.words[lastSegment.words.length - 1].id,
        isStartOfWord: false
      };
    }

    const segment = content[segmentIndex];
    const wordIndex = segment.words.findIndex(w => w.id === cursorPosition.wordId);

    if (wordIndex === -1) {
      console.log('Lost word reference, attempting resync');
      // Word not found in segment - get last valid word
      return {
        segmentId: segment.id,
        wordId: segment.words[segment.words.length - 1].id,
        isStartOfWord: false
      };
    }

    // Position is valid
    return cursorPosition;
  }, []);

  const transformSegment = useCallback((segment, index) => {
    const transformedSegment = {
      id: uuidv4(),
      speaker: segment.speaker,
      startTime: segment.start_time || segment.startTime,
      endTime: segment.end_time || segment.endTime,
      words: Array.isArray(segment.words) 
        ? segment.words.map((word, wordIndex) => ({
            id: uuidv4(),
            text: word.word || word.text,
            startTime: word.start || word.startTime,
            endTime: word.end || word.endTime,
            index: wordIndex
          }))
        : [{ 
            id: uuidv4(),
            text: segment.text,
            startTime: segment.start_time || segment.startTime,
            endTime: segment.end_time || segment.endTime,
            index: 0
          }],
      sourceReference: {
        fileId: segment.fileId,
        segmentId: segment.globalIndex || segment.id,
        index: index
      }
    };
    return transformedSegment;
  }, []);

  const splitSegmentAtCursor = useCallback((content, cursorPosition) => {
    // Validate cursor position before operation
    const validCursor = validateAndResyncCursor(content, cursorPosition);
    if (!validCursor) {
      console.warn('No valid cursor position for split');
      return content;
    }

    const newContent = content.map(segment => {
      if (segment.id !== validCursor.segmentId) return segment;

      const wordIndex = segment.words.findIndex(w => w.id === validCursor.wordId);
      if (wordIndex === -1) return segment;

      // If isStartOfWord is true, split before the word
      // If false, split after the word
      const splitIndex = validCursor.isStartOfWord ? wordIndex : wordIndex + 1;

      const firstHalfBase = {
        ...segment,
        words: segment.words.slice(0, splitIndex),
        endTime: segment.words[splitIndex - 1]?.endTime || segment.endTime
      };

      const secondHalfBase = {
        ...segment,
        words: segment.words.slice(splitIndex),
        startTime: segment.words[splitIndex]?.startTime || segment.startTime
      };

      const firstHalf = transformSegment(firstHalfBase, segment.index);
      const secondHalf = transformSegment(secondHalfBase, segment.index + 1);

      return [firstHalf, secondHalf];
    }).flat();

    const validContent = newContent.filter(Boolean);

    // Update cursor position
    if (validContent.length !== content.length) {
      const insertIndex = content.findIndex(s => s.id === validCursor.segmentId) + 1;
      updateCursorPosition({
        segmentId: validContent[insertIndex].id,
        wordId: validContent[insertIndex].words[0].id,
        isStartOfWord: true
      });
    }

    return validContent;
  }, [transformSegment, updateCursorPosition, validateAndResyncCursor]);

  const deleteWordAtCursor = useCallback((content, cursorPosition) => {
    const validCursor = validateAndResyncCursor(content, cursorPosition);
    if (!validCursor) {
      console.warn('No valid cursor position for delete');
      return content;
    }

    // In deleteWordAtCursor
    const updateCursorsAfterMerge = (mergedSegment, previousPosition) => {
      // Find appropriate word position in merged segment
      const wordIndex = Math.min(
        previousPosition.wordIndex,
        mergedSegment.words.length - 1
      );
      
      const newPosition = {
        segmentId: mergedSegment.id,
        wordId: mergedSegment.words[wordIndex].id,
        isStartOfWord: false
      };
      
      updateCursorPosition(newPosition);
    };

    const segmentIndex = content.findIndex(s => s.id === validCursor.segmentId);
    const currentSegment = content[segmentIndex];
    const wordIndex = currentSegment.words.findIndex(w => w.id === validCursor.wordId);
  
    // Check if we're at the start of a word in a segment with a same-speaker previous segment
    if (validCursor.isStartOfWord && segmentIndex > 0) {
      const previousSegment = content[segmentIndex - 1];
      if (previousSegment.speaker === currentSegment.speaker) {
        // Merge the segments
        const mergedSegment = {
          ...previousSegment,
          endTime: currentSegment.endTime,
          words: [
            ...previousSegment.words,
            ...currentSegment.words
          ].map((word, idx) => ({
            ...word,
            index: idx
          })),
          sourceReference: {
            ...previousSegment.sourceReference,
            wordRange: [
              previousSegment.sourceReference?.wordRange?.[0] || 0,
              previousSegment.words.length + currentSegment.words.length
            ]
          }
        };
  
        // Create new content array with merged segments
        const newContent = [
          ...content.slice(0, segmentIndex - 1),
          mergedSegment,
          ...content.slice(segmentIndex + 1)
        ];

        // Update cursors after merge
        updateCursorsAfterMerge(mergedSegment, { wordIndex });

        return newContent;
      }
    }
  
    // Handle regular word deletion
    if (wordIndex > 0) {
      updateCursorPosition({
        segmentId: validCursor.segmentId,
        wordId: currentSegment.words[wordIndex - 1].id
      });
    } else if (segmentIndex > 0) {
      const previousSegment = content[segmentIndex - 1];
      const lastWord = previousSegment.words[previousSegment.words.length - 1];
      updateCursorPosition({
        segmentId: previousSegment.id,
        wordId: lastWord.id
      });
    }
  
    return content.map(segment => {
      if (segment.id !== validCursor.segmentId) return segment;
  
      const updatedWords = segment.words.filter(word => word.id !== validCursor.wordId)
        .map((word, idx) => ({
          ...word,
          index: idx
        }));
      
      if (updatedWords.length === 0) {
        return null;
      }
  
      return {
        ...segment,
        words: updatedWords,
        startTime: updatedWords[0]?.startTime || segment.startTime,
        endTime: updatedWords[updatedWords.length - 1]?.endTime || segment.endTime,
        sourceReference: {
          ...segment.sourceReference,
          wordRange: [0, updatedWords.length]
        }
      };
    }).filter(Boolean);
  }, [updateCursorPosition, validateAndResyncCursor]);

  const mergeSegmentsWithSameSpeaker = useCallback((segments) => {
    return segments.reduce((acc, current) => {
      if (acc.length === 0) {
        return [current];
      }

      const lastSegment = acc[acc.length - 1];
      
      if (lastSegment.speaker === current.speaker) {
        // Merge the current segment with the last one
        const mergedSegment = {
          ...lastSegment,
          endTime: current.endTime,
          words: [
            ...lastSegment.words,
            ...current.words.map((word, idx) => ({
              ...word,
              index: lastSegment.words.length + idx
            }))
          ],
          sourceReference: {
            ...lastSegment.sourceReference,
            wordRange: [
              lastSegment.sourceReference?.wordRange?.[0] || 0,
              (lastSegment.words.length + current.words.length)
            ]
          }
        };
        return [...acc.slice(0, -1), mergedSegment];
      }

      return [...acc, current];
    }, []);
  }, []);

  const addToPapercut = useCallback((papercutId, selectedContent) => {
    const transformedContent = selectedContent.map(transformSegment);
    const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
    addContentToPapercut(papercutId, mergedContent);
  }, [addContentToPapercut, transformSegment, mergeSegmentsWithSameSpeaker]);

  const insertToPapercut = useCallback((papercutId, selectedContent) => {
    console.log('Insert called with:', { papercutId, selectedContent, cursorPosition });
    
    // Get current content and validate cursor
    const papercut = papercuts.find(p => p.id === papercutId);
    if (!papercut?.content) return;

    const validCursor = validateAndResyncCursor(papercut.content, cursorPosition);
    
    if (!validCursor) {
      console.log('No valid cursor position, appending to end');
      // Handle as append case
      const transformedContent = selectedContent.map(transformSegment);
      const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
      addContentToPapercut(papercutId, mergedContent);
      return;
    }

    const handleInsertComplete = (newContent, lastInsertedSegment) => {
      // Update both cursors to the same position
      const lastWord = lastInsertedSegment.words[lastInsertedSegment.words.length - 1];
      const newPosition = {
        segmentId: lastInsertedSegment.id,
        wordId: lastWord.id,
        isStartOfWord: false
      };
      
      updateCursorPosition(newPosition);
    };

    if (!Array.isArray(selectedContent) || selectedContent.length === 0) {
      console.warn('Invalid or empty content to insert');
      return;
    }
  
    if (!papercut.content || papercut.content.length === 0 || !validCursor?.segmentId || !validCursor?.wordId) {
      console.log('Papercut is empty or no cursor position, appending content');
      const transformedContent = selectedContent.map((segment, index) => 
        transformSegment(segment, index)
      );
      const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
      addContentToPapercut(papercutId, mergedContent);
  
      if (mergedContent.length > 0) {
        const lastSegment = mergedContent[mergedContent.length - 1];
        handleInsertComplete(mergedContent, lastSegment);
      }
      return;
    }
  
    const currentSegmentIndex = papercut.content.findIndex(
      segment => segment.id === validCursor.segmentId
    );
    
    if (currentSegmentIndex === -1) {
      console.warn('Cursor segment not found in papercut content');
      return;
    }
  
    const currentSegment = papercut.content[currentSegmentIndex];
    if (!currentSegment || !Array.isArray(currentSegment.words)) {
      console.warn('Invalid segment structure:', currentSegment);
      return;
    }
  
    const wordIndex = currentSegment.words.findIndex(
      word => word.id === validCursor.wordId
    );
  
    if (wordIndex === -1) {
      console.warn('Cursor word not found in segment');
      const lastWordIndex = currentSegment.words.length - 1;
      if (lastWordIndex >= 0) {
        validCursor.wordId = currentSegment.words[lastWordIndex].id;
      } else {
        console.warn('Segment has no words');
        return;
      }
    }
    
    const isAtEndPosition = (cursorPosition, currentSegment, wordIndex) => {
      if (!cursorPosition?.isStartOfWord) {
        // If we're at the end of a word (not start), treat as end position
        return true;
      }
      // If we're at start of word, treat as middle position for insertion
      return false;
    };

    const shouldInsertAfter = isAtEndPosition(validCursor, currentSegment, wordIndex);

    if (shouldInsertAfter) {
      console.log('Cursor at end of segment, inserting after without splitting');
      const transformedContent = selectedContent.map((segment, index) => 
        transformSegment(segment, currentSegmentIndex + 1 + index)
      );
      const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
      
      const newContent = [
        ...papercut.content.slice(0, currentSegmentIndex + 1),
        ...mergedContent,
        ...papercut.content.slice(currentSegmentIndex + 1)
      ];
      
      updatePapercutContent(papercutId, newContent);
      
      const lastInsertedSegment = mergedContent[mergedContent.length - 1];
      
      if (lastInsertedSegment && lastInsertedSegment.words.length > 0) {
        handleInsertComplete(newContent, lastInsertedSegment);
      }
      return;
    }
    
    console.log('Splitting segment and inserting content');
    const splitContent = splitSegmentAtCursor(papercut.content, validCursor);
    const insertIndex = currentSegmentIndex + 1;
    
    const transformedContent = selectedContent.map((segment, index) => 
      transformSegment(segment, insertIndex + index)
    );
    const mergedContent = mergeSegmentsWithSameSpeaker(transformedContent);
    
    const newContent = [
      ...splitContent.slice(0, insertIndex),
      ...mergedContent,
      ...splitContent.slice(insertIndex)
    ];
    
    const lastInsertedSegment = mergedContent[mergedContent.length - 1];
    
    updatePapercutContent(papercutId, newContent);
    
    if (lastInsertedSegment && lastInsertedSegment.words.length > 0) {
      handleInsertComplete(newContent, lastInsertedSegment);
    }
  }, [cursorPosition, papercuts, transformSegment, splitSegmentAtCursor, 
      updatePapercutContent, addContentToPapercut, mergeSegmentsWithSameSpeaker, updateCursorPosition, validateAndResyncCursor]);

  return {
    splitSegmentAtCursor,
    deleteWordAtCursor,
    addToPapercut,
    insertToPapercut,
    transformSegment
  };
}