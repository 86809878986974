import React from 'react';
import { Box } from '@mui/material';

export const ScrollContainer = ({ 
  contentRef, 
  onScroll,
  onContainerClick,
  children 
}) => (
  <Box 
    ref={contentRef}
    sx={{ 
      flex: 1,
      overflowY: 'auto',
      py: 2 
    }}
    onClick={onContainerClick}
    onScroll={onScroll}
  >
    {children}
  </Box>
);