import React, { forwardRef, useState, useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { animated } from '@react-spring/web';
import Paper from '@mui/material/Paper';
import { SegmentWord } from './SegmentWord';

const AnimatedPaper = animated(Paper);

const Segment = forwardRef(({ 
    segment,
    content,    
    segmentIndex,  
    isSelected,
    isHovered,
    theme,
    getSpeakerColor,
    onDeleteSegment,
    cursorPosition,
    selection,
    onWordClick,
    onWordHover,
    onClick,
    onMouseEnter,
    onMouseLeave,
    onSelectionStart,
    onSelectionUpdate,
    isWordInSelection,
    style,
    draggedSegment,
    dropIndicator,
    onDragStart,
    onDragEnd,
    onDragOver,
    onDrop,
    getHighlightStyle
  }, ref) => {
  const [fadeSelection, setFadeSelection] = useState(false);
  const [isTemporarilySelected, setIsTemporarilySelected] = useState(false);

  // Handle initial selection and fade
  useEffect(() => {
    if (isSelected) {
      setIsTemporarilySelected(true);
      const fadeTimer = setTimeout(() => {
        setFadeSelection(true);
      }, 100);

      const clearTimer = setTimeout(() => {
        setIsTemporarilySelected(false);
        setFadeSelection(false);
      }, 1000);

      return () => {
        clearTimeout(fadeTimer);
        clearTimeout(clearTimer);
      };
    }
  }, [isSelected]);

  const showDropIndicator = dropIndicator?.targetId === segment.id;
  const isBeingDragged = draggedSegment === segment.id;

  return (
    <AnimatedPaper
      ref={ref}
      elevation={isBeingDragged ? 4 : 1}
      onDragOver={(e) => onDragOver?.(e, segment, 'middle')}
      onDrop={(e) => onDrop?.(e, segment, 'middle')}
      onClick={onClick}
      onMouseEnter={() => onMouseEnter?.(segment.id)}
      onMouseLeave={onMouseLeave}
      style={{
        ...style,
        position: 'relative',
        // Removed transform to avoid offset issues:
        backgroundColor: isTemporarilySelected ? theme.palette.action.selected : theme.palette.background.paper,
        zIndex: style?.zIndex || 'auto',
      }}
      sx={{ 
        transition: theme.transitions.create([
          'box-shadow', 
          'opacity',
          'background-color'
        ], {
          duration: fadeSelection ? '0.8s' : '0.1s',
          easing: fadeSelection ? 'ease-out' : 'ease-in',
        }),
        opacity: isBeingDragged ? 0.5 : 1,
        '&:hover .drag-handle': {
          opacity: 1
        },
        ...(fadeSelection && {
          backgroundColor: `${theme.palette.background.paper} !important`,
        })
      }}
    >
      <Box 
        className="segment-content"
        sx={{ 
          display: 'flex',
          alignItems: 'stretch',
          p: 0,
          minHeight: '64px'
        }}
      >
        <Box 
          className="drag-handle"
          draggable={true}
          onDragStart={(e) => onDragStart?.(e, segment.id)}
          onDragEnd={onDragEnd}
          sx={{ 
            opacity: 0,
            transition: theme.transitions.create('opacity'),
            cursor: 'grab',
            '&:active': {
              cursor: 'grabbing'
            },
            px: 2,
            display: 'flex',
            alignItems: 'center',
            alignSelf: 'stretch',
            position: 'relative',
          }}
        >
          <DragIndicatorIcon color="action" />
        </Box>

        <Box 
          className="segment-text"
          sx={{ 
            flexGrow: 1,
            borderLeft: 3,
            borderColor: getSpeakerColor(segment.speaker).colors.edgeLine,
            pl: 2,
            userSelect: 'text',
            '& ::selection': {
              backgroundColor: 'transparent'
            }
          }}
        >
          <Typography 
            variant="subtitle2" 
            sx={{ 
              color: 'primary.main',
              mb: 0.5,
              fontWeight: 500,
              fontSize: '0.75rem',
              textTransform: 'uppercase',
              letterSpacing: '0.1em',
              userSelect: 'none'
            }}
          >
            {segment.speaker}
          </Typography>
          <Box>
            {segment.words.map((word) => (
              <SegmentWord
                key={word.id}
                word={word}
                segment={segment}
                content={content}  
                segmentIndex={segmentIndex} 
                isSelected={cursorPosition?.segmentId === segment.id && 
                            cursorPosition?.wordId === word.id}
                isInSelection={isWordInSelection(segment.id, word.id)}
                onWordClick={onWordClick}
                onWordHover={onWordHover}
                onSelectionStart={onSelectionStart}
                onSelectionUpdate={onSelectionUpdate}
                highlightStyle={getHighlightStyle?.(segment.id, word.id)}
                cursorPosition={cursorPosition}
              />
            ))}
          </Box>
        </Box>
      </Box>

      {isHovered && (
        <Box
          className="delete-button"
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            opacity: 0.7,
            '&:hover': {
              opacity: 1,
            },
            cursor: 'pointer',
            zIndex: 3
          }}
          onClick={(e) => {
            e.stopPropagation();
            onDeleteSegment?.(segment.id);
          }}
        >
          <DeleteIcon color="error" />
        </Box>
      )}

      {showDropIndicator && dropIndicator.offsetY != null && (
        <Box 
          sx={{
            position: 'absolute',
            top: `${dropIndicator.offsetY}px`,
            left: 0,
            right: 0,
            height: '2px',
            backgroundColor: 'red', // distinct color for debugging
            pointerEvents: 'none',
            zIndex: 9999
          }}
        />
      )}
    </AnimatedPaper>
  );
});

Segment.displayName = 'Segment';
export default Segment;
