import { useState, useCallback } from 'react';

export const usePapercutSelection = (content, updateCursorPosition) => {
  const [selection, setSelection] = useState({
    isSelecting: false,
    start: null,
    end: null
  });

  const isWordInSelection = useCallback((segmentId, wordId) => {
    if (!selection.start || !selection.end) return false;

    // Find indices of segments and words
    const startSegmentIndex = content.findIndex(s => s.id === selection.start.segmentId);
    const endSegmentIndex = content.findIndex(s => s.id === selection.end.segmentId);
    const currentSegmentIndex = content.findIndex(s => s.id === segmentId);

    if (startSegmentIndex === -1 || endSegmentIndex === -1 || currentSegmentIndex === -1) {
      return false;
    }

    // Determine direction of selection
    const isForwardSegment = startSegmentIndex <= endSegmentIndex;
    const firstSegmentIndex = isForwardSegment ? startSegmentIndex : endSegmentIndex;
    const lastSegmentIndex = isForwardSegment ? endSegmentIndex : startSegmentIndex;

    // Check if current segment is within range
    if (currentSegmentIndex < firstSegmentIndex || currentSegmentIndex > lastSegmentIndex) {
      return false;
    }

    // If in middle segment, all words are selected
    if (currentSegmentIndex > firstSegmentIndex && currentSegmentIndex < lastSegmentIndex) {
      return true;
    }

    // Get word indices
    const startWordIndex = content[startSegmentIndex].words.findIndex(w => w.id === selection.start.wordId);
    const endWordIndex = content[endSegmentIndex].words.findIndex(w => w.id === selection.end.wordId);
    const currentWordIndex = content[currentSegmentIndex].words.findIndex(w => w.id === wordId);

    // Handle selection within same segment
    if (startSegmentIndex === endSegmentIndex) {
      const minIndex = Math.min(startWordIndex, endWordIndex);
      const maxIndex = Math.max(startWordIndex, endWordIndex);
      return currentWordIndex >= minIndex && currentWordIndex <= maxIndex;
    }

    // Handle first and last segments
    if (currentSegmentIndex === firstSegmentIndex) {
      return isForwardSegment ? 
        currentWordIndex >= startWordIndex :
        currentWordIndex <= endWordIndex;
    }

    if (currentSegmentIndex === lastSegmentIndex) {
      return isForwardSegment ?
        currentWordIndex <= endWordIndex :
        currentWordIndex >= startWordIndex;
    }

    return true;
  }, [content, selection]);

  const handleSelectionStart = useCallback((segmentId, wordId) => {
    setSelection({
      isSelecting: true,
      start: { segmentId, wordId },
      end: { segmentId, wordId }
    });
  }, []);

  const handleSelectionUpdate = useCallback((segmentId, wordId) => {
    if (selection.isSelecting) {
      setSelection(prev => ({
        ...prev,
        end: { segmentId, wordId }
      }));
    }
  }, [selection.isSelecting]);

  const handleSelectionEnd = useCallback(() => {
    setSelection(prev => ({
      ...prev,
      isSelecting: false
    }));
  }, []);

  const clearSelection = useCallback(() => {
    setSelection({
      isSelecting: false,
      start: null,
      end: null
    });
  }, []);

  const getSelectedContent = useCallback(() => {
    if (!selection.start || !selection.end) return null;

    const startSegmentIndex = content.findIndex(s => s.id === selection.start.segmentId);
    const endSegmentIndex = content.findIndex(s => s.id === selection.end.segmentId);
    
    if (startSegmentIndex === -1 || endSegmentIndex === -1) return null;

    try {
      const selectedSegments = [];
      const firstIndex = Math.min(startSegmentIndex, endSegmentIndex);
      const lastIndex = Math.max(startSegmentIndex, endSegmentIndex);

      for (let i = firstIndex; i <= lastIndex; i++) {
        const segment = content[i];
        const selectedWords = segment.words.filter(word => 
          isWordInSelection(segment.id, word.id)
        );

        // Include segment even if it only has one word selected
        if (selectedWords.length > 0) {  // Changed from > 1 to > 0
          selectedSegments.push({
            ...segment,
            words: selectedWords
          });
        }
      }

      console.log('Selected content:', selectedSegments);
      // Return content even for single-word selections
      return selectedSegments.length > 0 ? selectedSegments : null;
    } catch (error) {
      console.error('Error getting selected content:', error);
      return null;
    }
  }, [content, selection, isWordInSelection]);

  return {
    selection,
    isWordInSelection,
    handleSelectionStart,
    handleSelectionUpdate,
    handleSelectionEnd,
    clearSelection,
    getSelectedContent
  };
};
