// useProcessTranscripts.js
import { useState, useEffect } from 'react';

export const GROUP_BREAK_THRESHOLD = 5;
export const PAUSE_INDICATOR_THRESHOLD = 1;

const useProcessTranscripts = (transcripts) => {
  const [displayContent, setDisplayContent] = useState([]);
  const [isProcessing, setIsProcessing] = useState(false);

  useEffect(() => {
    setIsProcessing(true);
    
    if (!transcripts.length) {
      setDisplayContent([]);
      setIsProcessing(false);
      return;
    }

    const processTranscripts = () => {
      let globalWordIndex = 0;
      return transcripts.map(transcript => {
        const content = JSON.parse(transcript.content);
        const fileContent = content.processed_data || content;
        
        if (!fileContent.transcript || !fileContent.transcript.segments) {
          console.log("Transcript missing segments:", transcript);
          return null;
        }

        const fileSegments = [];
        fileContent.transcript.segments.forEach((segment, segmentIndex) => {
          if (segmentIndex > 0) {
            const prevSegment = fileContent.transcript.segments[segmentIndex - 1];
            const timeGap = segment.start_time - prevSegment.end_time;
            
            if (timeGap >= PAUSE_INDICATOR_THRESHOLD) {
              // Insert pause "word"
              fileSegments.push({
                startTime: prevSegment.end_time,
                endTime: segment.start_time,
                speaker: prevSegment.speaker,
                fileId: transcript.id,
                fileName: transcript.name,
                globalIndex: `${transcript.id}-pause-${segmentIndex}`,
                words: [{
                  word: '(...)',
                  id: `${transcript.id}-pause-${segmentIndex}`,
                  globalIndex: globalWordIndex++,
                  startTime: prevSegment.end_time,
                  endTime: segment.start_time,
                  position: {
                    segment: segmentIndex,
                    word: -1
                  }
                }],
                metadata: {
                  isPause: true,
                  transcript: {
                    fileId: transcript.id,
                    fileName: transcript.name
                  }
                }
              });
            }
          }

          const processedWords = segment.words.map((word, wordIndex) => ({
            ...word,
            id: `${transcript.id}-${segmentIndex}-${wordIndex}`,
            globalIndex: globalWordIndex++,
            position: {
              segment: segmentIndex,
              word: wordIndex
            }
          }));
          
          fileSegments.push({
            ...segment,
            startTime: segment.start_time || segment.start,
            endTime: segment.end_time || segment.end,
            speaker: segment.speaker || 'Unknown',
            fileId: transcript.id,
            fileName: transcript.name,
            globalIndex: `${transcript.id}-${segmentIndex}`,
            words: processedWords,
            metadata: {
              ...segment.metadata,
              transcript: {
                fileId: transcript.id,
                fileName: transcript.name
              }
            }
          });
        });

        // Group segments by speaker and major time gaps
        const groupedSegments = [];
        let currentGroup = [];

        fileSegments.forEach((segment, index) => {
          if (currentGroup.length === 0) {
            currentGroup.push(segment);
          } else {
            const lastSegment = currentGroup[currentGroup.length - 1];
            const isSameSpeaker = lastSegment.speaker === segment.speaker;
            const isPause = segment.metadata && segment.metadata.isPause;

            if (!isSameSpeaker || (segment.startTime - lastSegment.endTime > GROUP_BREAK_THRESHOLD && !isPause)) {
              if (currentGroup.length > 0) {
                groupedSegments.push(currentGroup);
              }
              currentGroup = [segment];
            } else {
              currentGroup.push(segment);
            }
          }
        });

        if (currentGroup.length > 0) {
          groupedSegments.push(currentGroup);
        }

        return {
          fileId: transcript.id,
          fileName: transcript.name,
          groupedSegments
        };
      }).filter(Boolean);
    };

    const processed = processTranscripts();
    setDisplayContent(processed);
    setIsProcessing(false);
  }, [transcripts]);

  return [displayContent, isProcessing];
};

export default useProcessTranscripts;