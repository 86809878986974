import { useState, useCallback } from 'react';
import { useSprings } from '@react-spring/web';

export const useDragAndDrop = (content = [], handleContentUpdate, selectedSegments = new Set(), segmentRefs) => {
  const [draggedSegment, setDraggedSegment] = useState(null);
  const [dropIndicator, setDropIndicator] = useState(null);

  const [springs, api] = useSprings(
    content?.length || 0,
    index => ({
      y: index * 20,
      scale: 1,
      zIndex: 0,
      immediate: false,
      config: { tension: 300, friction: 32 }
    })
  );

  const handleDragStart = useCallback((e, segmentId) => {
    e.dataTransfer.setData('text/plain', ''); // Required for Firefox
    setDraggedSegment(segmentId);

    const draggedIndex = content.findIndex(s => s.id === segmentId);
    if (draggedIndex !== -1) {
      api.start(i => {
        if (i === draggedIndex) {
          return { scale: 1.02, zIndex: 1, immediate: true };
        }
        return {};
      });
    }
  }, [content, api]);

  const handleDragEnd = useCallback(() => {
    setDraggedSegment(null);
    setDropIndicator(null);

    // Reset all springs
    api.start(i => ({
      scale: 1,
      zIndex: 0,
      immediate: true
    }));
  }, [api]);

  const updateDropIndicator = useCallback((newIndicator) => {
    setDropIndicator(prev => {
      if (
        prev?.targetId !== newIndicator?.targetId ||
        prev?.position !== newIndicator?.position ||
        prev?.offsetY !== newIndicator?.offsetY
      ) {
        return newIndicator;
      }
      return prev;
    });
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    if (!draggedSegment) return;
  
    const cursorY = e.clientY;
    const segmentPositions = content
      .map(seg => {
        const el = segmentRefs?.current[seg.id];
        if (!el) return null;
        const rect = el.getBoundingClientRect();
        return { id: seg.id, top: rect.top, bottom: rect.bottom, height: rect.height };
      })
      .filter(Boolean);
  
    // If no segments, treat entire area as empty
    if (segmentPositions.length === 0) {
      // Dropping anywhere in an empty state inserts at 0
      updateDropIndicator({ targetId: null, position: 'empty', offsetY: cursorY });
      return;
    }
  
    const midpoints = segmentPositions.map(s => s.top + s.height / 2);
  
    let insertIndex = null;
  
    // If cursor is above the first segment midpoint
    if (cursorY < midpoints[0]) {
      insertIndex = 0; // always above the first segment
    } else if (cursorY > midpoints[midpoints.length - 1]) {
      // If cursor is below the last segment midpoint,
      // always allow insertion after the last segment
      insertIndex = content.length;
    } else {
      // Cursor is between the first and last midpoint
      // Find the closest midpoint logic or just find the gap:
      let closestMidIndex = 0;
      let closestDistance = Infinity;
      for (let i = 0; i < midpoints.length; i++) {
        const dist = Math.abs(cursorY - midpoints[i]);
        if (dist < closestDistance) {
          closestDistance = dist;
          closestMidIndex = i;
        }
      }
  
      // Decide insertIndex based on closest midpoint
      if (cursorY < midpoints[closestMidIndex]) {
        insertIndex = closestMidIndex;
      } else {
        insertIndex = closestMidIndex + 1;
      }
    }
  
    // Check if insertion changes order
    const draggedIndex = content.findIndex(s => s.id === draggedSegment);
    if (draggedIndex === -1) {
      updateDropIndicator({ targetId: null, position: null, offsetY: null });
      return;
    }
  
    if (insertIndex === draggedIndex || insertIndex === draggedIndex + 1) {
      updateDropIndicator({ targetId: null, position: null, offsetY: null });
      return;
    }
  
    const position = insertIndex < draggedIndex ? 'top' : 'bottom';
    updateDropIndicator({
      targetId: null,
      position,
      offsetY: cursorY
    });
  }, [draggedSegment, segmentRefs, content, updateDropIndicator]);
  

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    if (!draggedSegment || !dropIndicator) return;

    const newContent = [...content];
    const draggedIndex = newContent.findIndex(s => s.id === draggedSegment);
    if (draggedIndex === -1) return;

    const [removed] = newContent.splice(draggedIndex, 1);

    // Recompute midpoints to finalize insertIndex based on dropIndicator.offsetY
    const segmentPositions = content.map(seg => {
      const el = segmentRefs?.current[seg.id];
      if (!el) return null;
      const rect = el.getBoundingClientRect();
      return { id: seg.id, top: rect.top, bottom: rect.bottom, height: rect.height };
    }).filter(Boolean);

    const midpoints = segmentPositions.map(s => s.top + s.height / 2);
    const cursorY = dropIndicator.offsetY;
    let closestMidIndex = 0;
    let closestDistance = Infinity;
    for (let i = 0; i < midpoints.length; i++) {
      const dist = Math.abs(cursorY - midpoints[i]);
      if (dist < closestDistance) {
        closestDistance = dist;
        closestMidIndex = i;
      }
    }

    let insertIndex = null;
    if (midpoints.length === 0) {
      insertIndex = 0;
    } else if (cursorY < midpoints[0]) {
      insertIndex = 0;
    } else if (cursorY > midpoints[midpoints.length - 1]) {
      insertIndex = content.length;
    } else {
      // Use same logic as handleDragOver for consistency
      const TOLERANCE = 10;
      if (closestDistance < TOLERANCE) {
        insertIndex = (cursorY < midpoints[closestMidIndex]) ? closestMidIndex : closestMidIndex + 1;
      } else {
        insertIndex = (cursorY < midpoints[closestMidIndex]) ? closestMidIndex : closestMidIndex + 1;
      }
    }

    if (insertIndex === null) {
      insertIndex = content.length;
    }

    newContent.splice(insertIndex, 0, removed);
    handleContentUpdate?.(newContent, 'reorder');
    handleDragEnd();
  }, [content, draggedSegment, dropIndicator, handleContentUpdate, segmentRefs, handleDragEnd]);

  return {
    draggedSegment,
    dropIndicator,
    springs,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop
  };
};

export default useDragAndDrop;
