// src/components/Media/FileSystemTree.js
import React, { useState, useCallback, useEffect } from 'react';
import { Box } from '@mui/material';
import { useFileSystem } from '../../contexts/FileSystemContext';
import FileItem from './FileItem';

const FileSystemTree = ({ parentId, onParentChange, depth = 0 }) => {
  const { getDirectoryItems, moveItem, selectedItems, files } = useFileSystem();
  const [expandedFolders, setExpandedFolders] = useState(new Set());
  const [dropTarget, setDropTarget] = useState(null);
  const items = getDirectoryItems(parentId);

  useEffect(() => {
    onParentChange(parentId);
  }, [parentId, onParentChange]);

  const handleDragOver = useCallback((id, position) => {
    setDropTarget({ id, position });
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    const draggedItemId = e.dataTransfer.getData('text');
    
    if (!draggedItemId || !dropTarget) {
      setDropTarget(null);
      return;
    }

    let itemsToMove = selectedItems.includes(draggedItemId) ? selectedItems : [draggedItemId];

    const moveItems = (targetId, position) => {
      const targetItem = files[targetId];
      const targetParentId = position === 'inside' ? targetId : targetItem.parentId;
      const targetIndex = position === 'inside' 
        ? Object.values(files).filter(f => f.parentId === targetId).length 
        : items.findIndex(item => item.id === targetId) + (position === 'after' ? 1 : 0);

      itemsToMove.forEach((itemId, index) => {
        if (itemId !== targetId) {  // Prevent moving a folder into itself
          moveItem(itemId, targetParentId, (targetIndex + index) * 1000);
        }
      });

      if (position === 'inside') {
        setExpandedFolders(prev => new Set([...prev, targetId]));
      }
    };

    moveItems(dropTarget.id, dropTarget.position);
    setDropTarget(null);
    // Removed setSelectedItems([]) to preserve selection after dropping
  }, [dropTarget, items, moveItem, selectedItems, files]);

  const handleToggleExpand = useCallback((folderId) => {
    setExpandedFolders(prev => {
      const next = new Set(prev);
      if (next.has(folderId)) {
        next.delete(folderId);
      } else {
        next.add(folderId);
      }
      return next;
    });
  }, []);

  return (
    <Box
      onDragOver={(e) => e.preventDefault()}
      onDrop={handleDrop}
    >
      {items.map((item, index) => (
        <React.Fragment key={item.id}>
          {dropTarget?.id === item.id && dropTarget?.position === 'before' && (
            <Box sx={{ height: '2px', bgcolor: 'primary.main', mx: 1 }} />
          )}
          <FileItem
            file={item}
            depth={depth}
            onDragOver={handleDragOver}
            isExpanded={expandedFolders.has(item.id)}
            onToggleExpand={handleToggleExpand}
            index={index}
          />
          {item.type === 'folder' && expandedFolders.has(item.id) && (
            <FileSystemTree 
              parentId={item.id} 
              onParentChange={onParentChange}
              depth={depth + 1}
            />
          )}
          {dropTarget?.id === item.id && dropTarget?.position === 'after' && (
            <Box sx={{ height: '2px', bgcolor: 'primary.main', mx: 1 }} />
          )}
        </React.Fragment>
      ))}
    </Box>
  );
};

export default FileSystemTree;
