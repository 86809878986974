// src/components/common/CopySnackbar.js
import React from 'react';
import { Snackbar, Alert } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

export const CopySnackbar = ({ open, onClose }) => (
  <Snackbar
    open={open}
    autoHideDuration={1500}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
  >
    <Alert 
      icon={<CheckCircleIcon />}
      severity="info"  // Changed from success to info
      variant="filled"
      sx={{ 
        bgcolor: 'primary.main',  // Changed from success.main to primary.main
        '& .MuiAlert-icon': {
          opacity: 1
        }
      }}
    >
      Copied to clipboard
    </Alert>
  </Snackbar>
);

export default CopySnackbar;