import { useContext, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import { PapercutHistoryContext } from '../../contexts/PapercutHistoryContext';

const DEBOUNCE_DELAY = 1000;

export const usePapercutHistory = () => {
  const context = useContext(PapercutHistoryContext);
  if (!context) {
    throw new Error('usePapercutHistory must be used within PapercutHistoryProvider');
  }

  const [state, dispatch] = context;
  
  // Use useRef to maintain a stable reference to the debounced function
  const debouncedPushStateRef = useRef();
  
  // Initialize the debounced function if it doesn't exist
  if (!debouncedPushStateRef.current) {
    debouncedPushStateRef.current = debounce((newState) => {
      dispatch({ type: 'PUSH_STATE', payload: newState });
    }, DEBOUNCE_DELAY);
  }

  // Wrap the stable reference in useCallback
  const pushState = useCallback((newState) => {
    debouncedPushStateRef.current(newState);
  }, []);

  const undo = useCallback(() => {
    dispatch({ type: 'UNDO' });
  }, [dispatch]);

  const redo = useCallback(() => {
    dispatch({ type: 'REDO' });
  }, [dispatch]);

  const clearHistory = useCallback(() => {
    dispatch({ type: 'CLEAR_HISTORY' });
  }, [dispatch]);

  const restoreHistory = useCallback((history) => {
    dispatch({ type: 'RESTORE_HISTORY', payload: history });
  }, [dispatch]);

  return {
    currentState: state.present,
    pushState,
    undo,
    redo,
    clearHistory,
    restoreHistory,
    canUndo: state.past.length > 0,
    canRedo: state.future.length > 0
  };
};