// components/Viewers/PapercutViewer/EmptyState.js
import React from 'react';
import { Box, Typography } from '@mui/material';

export const EmptyState = () => (
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    }}
  >
    <Typography 
      variant="body1" 
      sx={{ 
        fontStyle: 'italic',
        textAlign: 'center',
        color: 'text.disabled',
      }}
    >
      Select your papercuts to show here
    </Typography>
  </Box>
);

export default EmptyState;