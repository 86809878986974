import React, { useCallback, useMemo, useState, useEffect, useRef } from 'react';
import { Box, useTheme } from '@mui/material';

// Local components
import { ScrollContainer } from './ScrollContainer';
import { MetadataPanel } from './MetadataPanel';
import { SegmentList } from './SegmentList';
import { EmptyState } from './EmptyState';
import { CopySnackbar } from '../../../components/common/CopySnackbar';

// Contexts and hooks
import { useSpeakerColors } from '../../../contexts/SpeakerColorContext';
import { usePapercuts } from '../../../contexts/PapercutContext';
import { 
  usePapercutUI, 
  usePapercutActions
} from '../../../hooks/usePapercut';
import { useClipboard } from '../../../hooks/useClipboard';
import { usePapercutCursor } from '../../../hooks/usePapercut/usePapercutCursor';
import { useCursorDebug } from '../../../hooks/useCursorDebug';

// A placeholder EmptyDropZone component for demonstration.
// Style as needed or import from where you defined it.
const EmptyDropZone = ({ onDragOver, onDrop, active, children }) => (
  <Box
    onDragOver={onDragOver}
    onDrop={onDrop}
    sx={{
      py: 4,
      backgroundColor: active ? 'grey.100' : 'transparent',
      textAlign: 'center',
      transition: 'all 0.3s ease',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    }}
  >
    {children}
  </Box>
);

const PapercutContent = ({ papercutId }) => {
  const theme = useTheme();
  const { getSpeakerColor } = useSpeakerColors();
  const { deleteWordAtCursor, splitSegmentAtCursor, insertToPapercut } = usePapercutActions();
  const { copy, paste } = useClipboard('papercut');

  const [copyFeedback, setCopyFeedback] = useState(false);
  const [lastCopiedContent, setLastCopiedContent] = useState(null);
  
  const containerRef = useRef(null);

  const {
    papercuts,
    updatePapercutContent,
    lastInsertedSegmentId,
    activeTab
  } = usePapercuts();

  const content = useMemo(() => {
    const papercut = papercuts.find(p => p.id === papercutId);
    return papercut ? papercut.content : [];
  }, [papercuts, papercutId]);

  const handleContentUpdate = useCallback((newContent, operation) => {
    console.log('Updating content:', newContent, 'Operation:', operation);
    updatePapercutContent(papercutId, newContent);
  }, [papercutId, updatePapercutContent]);

  const {
    cursor,
    selection,
    updateCursor,
    endSelection,
    clearSelection,
    getSelectedContent,
    isWordInSelection,
    handleKeyboardNavigation
  } = usePapercutCursor(content);

  const {
    hoveredSegment,
    hoveredWord,
    setHoveredSegment,
    handleWordHover,
    contentRef,
    setSegmentRef,
    handleScroll,
    draggedSegment,
    dropIndicator,
    springs,
    handleDragStart,
    handleDragEnd,
    handleDragOver,
    handleDrop,
    handleMouseMove,
    handleMouseLeave
  } = usePapercutUI(lastInsertedSegmentId, content, handleContentUpdate);

  const handleDeleteSegment = useCallback((segmentId) => {
    const newContent = content.filter(segment => segment.id !== segmentId);
    handleContentUpdate(newContent, 'delete');
  }, [content, handleContentUpdate]);

  useEffect(() => {
    const handleKeyboardCopy = async (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'c' && !selection.isCollapsed) {
        event.preventDefault();
        const selectedContent = getSelectedContent();
        if (selectedContent && selectedContent.length > 0) {
          const validContent = selectedContent.filter(segment => segment && segment.words);
          if (validContent.length > 0) {
            const success = copy(validContent);
            
            if (success) {
              setLastCopiedContent(validContent);
              setCopyFeedback(true);
              clearSelection();
              
              setTimeout(() => {
                setLastCopiedContent(null);
                setCopyFeedback(false);
              }, 1500);
            }
          }
        }
      }
    };
    
    document.addEventListener('keydown', handleKeyboardCopy);
    return () => {
      document.removeEventListener('keydown', handleKeyboardCopy);
    };
  }, [copy, selection, getSelectedContent, clearSelection]);

  useEffect(() => {
    const handleKeyboardPaste = async (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
        console.log('Paste operation initiated');
        const success = paste(({ content, source }) => {
          console.log('Pasting content:', { content, source, activeTab });
          insertToPapercut(activeTab, content);
        });

        if (success) {
          console.log('Paste operation successful');
          event.preventDefault();
        }
      }
    };

    document.addEventListener('keydown', handleKeyboardPaste);
    return () => {
      document.removeEventListener('keydown', handleKeyboardPaste);
    };
  }, [paste, activeTab, insertToPapercut]);

  const handleKeyDown = useCallback((event) => {
    handleKeyboardNavigation(event);
    
    // Handle delete/backspace
    if (event.key === 'Delete' || event.key === 'Backspace') {
      event.preventDefault();
      if (!selection.isCollapsed) {
        const selectedContent = getSelectedContent();
        if (selectedContent) {
          // Handle deleting selected content
          const newContent = content.filter(segment => 
            !selectedContent.some(s => s.id === segment.id)
          );
          handleContentUpdate(newContent, 'delete');
          clearSelection();
        }
      } else if (cursor) {
        const newContent = deleteWordAtCursor(content, cursor);
        handleContentUpdate(newContent, 'delete');
      }
    }
    
    // Handle Enter
    if (event.key === 'Enter' && cursor) {
      event.preventDefault();
      const newContent = splitSegmentAtCursor(content, cursor);
      handleContentUpdate(newContent, 'split');
    }
  }, [
    content,
    cursor,
    selection,
    handleKeyboardNavigation,
    getSelectedContent,
    clearSelection,
    deleteWordAtCursor,
    splitSegmentAtCursor,
    handleContentUpdate
  ]);

  const handleWordClick = useCallback((segmentId, wordId, isStartOfWord = false) => {
    updateCursor({ segmentId, wordId, isStartOfWord });
  }, [updateCursor]);

  const handleSelectionStart = useCallback((segmentId, wordId) => {
    updateCursor({ segmentId, wordId, isStartOfWord: false }, true);
  }, [updateCursor]);

  const handleSelectionUpdate = useCallback((segmentId, wordId) => {
    if (selection.isSelecting) {
      updateCursor({ segmentId, wordId, isStartOfWord: false });
    }
  }, [selection.isSelecting, updateCursor]);

  const handleSelectionComplete = useCallback(() => {
    endSelection();
  }, [endSelection]);

  const handleContainerClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      clearSelection();
    }
  }, [clearSelection]);

  const getHighlightStyle = useCallback((segmentId, wordId) => {
    if (lastCopiedContent && lastCopiedContent.some(segment => 
      segment.id === segmentId && 
      segment.words.some(word => word.id === wordId)
    )) {
      return {
        backgroundColor: theme.palette.primary.main + (theme.palette.mode === 'dark' ? '80' : '40'),
        transition: 'background-color 0.3s ease'
      };
    }
    return {};
  }, [lastCopiedContent, theme]);

  useCursorDebug(content, cursor, cursor, 'PapercutContent');

  return (
    <Box 
      ref={containerRef}
      sx={{ 
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      tabIndex={0}
      onKeyDown={handleKeyDown}
      onMouseUp={handleSelectionComplete}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
    >
      {content.length === 0 ? (
        // Wrap EmptyState in EmptyDropZone to allow dropping into empty papercut
        <EmptyDropZone
          onDragOver={(e) => handleDragOver(e, null, 'empty')}
          onDrop={(e) => handleDrop(e, null, 'empty')}
          active={dropIndicator?.position === 'empty'}
        >
          <EmptyState />
        </EmptyDropZone>
      ) : (
        <>
          <ScrollContainer
            contentRef={contentRef}
            onScroll={handleScroll}
            onContainerClick={handleContainerClick}
          >
            <SegmentList
              containerRef={contentRef} // Pass the scroll container ref as containerRef to SegmentList
              content={content}
              cursorPosition={cursor}
              selection={selection}
              isWordInSelection={isWordInSelection}
              onWordClick={handleWordClick}
              onWordHover={handleWordHover}
              onSegmentHover={setHoveredSegment}
              onDeleteSegment={handleDeleteSegment}
              onSelectionStart={handleSelectionStart}
              onSelectionUpdate={handleSelectionUpdate}
              onSelectionEnd={endSelection}
              onSegmentRef={setSegmentRef}
              hoveredSegment={hoveredSegment}
              getSpeakerColor={getSpeakerColor}
              theme={theme}
              draggedSegment={draggedSegment}
              dropIndicator={dropIndicator}
              springs={springs}
              onDragStart={handleDragStart}
              onDragEnd={handleDragEnd}
              onDragOver={handleDragOver}
              onDrop={handleDrop}
              updateCursorPosition={updateCursor}
              getHighlightStyle={getHighlightStyle}
            />
          </ScrollContainer>
          <MetadataPanel 
            hoveredWord={hoveredWord}
            content={content}
          />
          <CopySnackbar 
            open={copyFeedback} 
            onClose={() => setCopyFeedback(false)} 
          />
        </>
      )}
    </Box>
  );
};

export default PapercutContent;
