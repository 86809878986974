import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  Link,
  Paper,
  Divider,
  ThemeProvider,
  createTheme,
  useMediaQuery,
  CircularProgress,
} from '@mui/material';
import { useAuth } from '../../contexts/AuthContext'; // Import local auth context
import PaperCutLogo from '../../images/login/PaperCut_Login_banner.png';

// Custom theme
const theme = createTheme({
  palette: {
    background: {
      default: '#ffffff',
      paper: '#ffffff',
    },
    text: {
      primary: '#000000',
      secondary: '#555555',
    },
    action: {
      hover: '#f0f0f0',
    },
  },
  typography: {
    fontFamily: 'Roboto, Arial, sans-serif',
  },
});

const LoginPage = () => {
  const { login: localLogin, error: localError } = useAuth(); // Get local auth methods
  const [email, setEmail] = useState(''); 
  const [password, setPassword] = useState(''); 
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const isMobile = useMediaQuery('(max-width:600px)');

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('[LoginPage] handleSubmit called with:', { email, password });
    setLoading(true);
    setError(null);

    // Attempt DB login first
    try {
      console.log('[LoginPage] Attempting DB login');
      const res = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password })
      });
      

      console.log('[LoginPage] DB login response status:', res.status);

      if (!res.ok) {
        const errData = await res.json().catch(() => ({}));
        console.log('[LoginPage] DB login error data:', errData);
        throw new Error(errData.error || 'Failed to login via DB');
      }

      const data = await res.json();
      console.log('[LoginPage] DB login success data:', data);

      // Store token and user from DB login
      localStorage.setItem('token', data.token);
      localStorage.setItem('user', JSON.stringify(data.user));

      console.log('[LoginPage] DB login succeeded, redirecting...');
      // Redirect or reload the page after successful DB login
      window.location.href = '/';
    } catch (dbError) {
      console.error('[LoginPage] DB login failed:', dbError.message);

      // If DB login fails, attempt local auth fallback
      console.log('[LoginPage] Attempting local fallback login...');
      const localSuccess = await localLogin({ email, password });
      console.log('[LoginPage] Local fallback login success?', localSuccess);

      if (localSuccess) {
        console.log('[LoginPage] Local fallback succeeded, redirecting...');
        // Local auth success: redirect or reload
        window.location.href = '/';
      } else {
        console.log('[LoginPage] Neither DB nor local fallback succeeded');
        // Neither DB login nor local auth succeeded
        setError(dbError.message || localError);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <Container component="main" maxWidth="sm">
        <Paper
          elevation={3}
          sx={{
            mt: isMobile ? 4 : 8,
            p: isMobile ? 2 : 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            bgcolor: 'background.paper',
          }}
        >
          {/* Logo */}
          <Box sx={{ mb: 2, width: '100%', display: 'flex', justifyContent: 'center' }}>
            <img
              src={PaperCutLogo}
              alt="PaperCut Logo"
              style={{ maxWidth: '80%', height: 'auto' }}
            />
          </Box>

          {/* Description */}
          <Typography
            component="h1"
            variant="body1"
            align="center"
            gutterBottom
            color="text.primary"
            sx={{ fontSize: '1rem', lineHeight: '1.6', mb: 2 }}
          >
            Papercut is an AI-powered paper-edit tool that{' '}
            <Box component="span" fontWeight="bold">
              helps TV and documentary post-production teams work with transcripts
            </Box>{' '}
            faster than ever before.
          </Typography>

          {/* Form */}
          <Box component="form" noValidate sx={{ mt: 1, width: '100%' }} onSubmit={handleSubmit}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              InputLabelProps={{
                style: { color: theme.palette.text.secondary },
              }}
              InputProps={{
                style: { color: theme.palette.text.primary, borderColor: '#ddd' },
              }}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              InputLabelProps={{
                style: { color: theme.palette.text.secondary },
              }}
              InputProps={{
                style: { color: theme.palette.text.primary, borderColor: '#ddd' },
              }}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              disabled={loading}
              sx={{
                mt: 3,
                mb: 2,
                bgcolor: 'text.primary',
                color: 'background.paper',
                borderRadius: '8px',
                '&:hover': { bgcolor: '#444444' },
                textTransform: 'uppercase',
              }}
            >
              {loading ? <CircularProgress size={24} color="inherit" /> : 'Sign In'}
            </Button>
            {(error || localError) && (
              <Typography color="error" align="center" sx={{ mt: 1 }}>
                {error || localError}
              </Typography>
            )}
          </Box>

          <Divider sx={{ my: 3, width: '100%', bgcolor: 'text.secondary' }} />

          {/* Beta CTA */}
          <Typography variant="body2" color="text.secondary" align="center">
            PaperCut is currently in closed beta. If you work with transcripts and want to give it a try,{' '}
            <Link
              href="https://forms.gle/Uzj7wdHCBfmhdD7JA"
              target="_blank"
              rel="noopener"
              color="text.primary"
              sx={{ textDecoration: 'underline', fontWeight: 'bold' }}
            >
              sign up here
            </Link>
            , and we’ll be in touch.
          </Typography>
        </Paper>
      </Container>
    </ThemeProvider>
  );
};

export default LoginPage;
