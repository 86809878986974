// usePapercutCursor.js
import { useState, useCallback, useMemo } from 'react';
import { usePapercuts } from '../../contexts/PapercutContext';

export const usePapercutCursor = (content) => {
  const { updateCursorPosition } = usePapercuts();

  const [cursorState, setCursorState] = useState({
    cursor: {
      segmentId: null,
      wordId: null,
      isStartOfWord: false
    },
    selection: {
      isSelecting: false,
      anchor: null,
      focus: null,
      isCollapsed: true
    }
  });

  // Build a global list of words for precise indexing
  const globalWordList = useMemo(() => {
    return content.reduce((acc, segment) => {
      segment.words.forEach(word => {
        acc.push({ segmentId: segment.id, wordId: word.id });
      });
      return acc;
    }, []);
  }, [content]);

  const findGlobalWordIndex = useCallback((segmentId, wordId) => {
    return globalWordList.findIndex(w => w.segmentId === segmentId && w.wordId === wordId);
  }, [globalWordList]);

  const isWordInSelection = useCallback((segmentId, wordId) => {
    const { selection } = cursorState;
    if (!selection.anchor || !selection.focus || selection.isCollapsed) return false;

    const startSegmentIndex = content.findIndex(s => s.id === selection.anchor.segmentId);
    const endSegmentIndex = content.findIndex(s => s.id === selection.focus.segmentId);

    if (startSegmentIndex === -1 || endSegmentIndex === -1) return false;

    const anchorWordIndex = content[startSegmentIndex].words.findIndex(w => w.id === selection.anchor.wordId);
    const focusWordIndex = content[endSegmentIndex].words.findIndex(w => w.id === selection.focus.wordId);

    const anchorOnWord = anchorWordIndex !== -1;
    const focusOnWord = focusWordIndex !== -1;

    const currentSegmentIndex = content.findIndex(s => s.id === segmentId);
    if (currentSegmentIndex === -1) return false;

    // If anchor or focus is not on a word, select entire segments
    if (!anchorOnWord || !focusOnWord) {
      const isForward = startSegmentIndex <= endSegmentIndex;
      const firstIndex = isForward ? startSegmentIndex : endSegmentIndex;
      const lastIndex = isForward ? endSegmentIndex : startSegmentIndex;

      if (currentSegmentIndex < firstIndex || currentSegmentIndex > lastIndex) return false;
      return true; // entire segment selected
    }

    // Both anchor and focus are on words, do precise word-level selection
    const anchorGlobalIndex = findGlobalWordIndex(selection.anchor.segmentId, selection.anchor.wordId);
    const focusGlobalIndex = findGlobalWordIndex(selection.focus.segmentId, selection.focus.wordId);
    if (anchorGlobalIndex === -1 || focusGlobalIndex === -1) return false;

    const minGlobal = Math.min(anchorGlobalIndex, focusGlobalIndex);
    const maxGlobal = Math.max(anchorGlobalIndex, focusGlobalIndex);

    const currentWordGlobalIndex = findGlobalWordIndex(segmentId, wordId);
    if (currentWordGlobalIndex === -1) return false;

    return currentWordGlobalIndex >= minGlobal && currentWordGlobalIndex <= maxGlobal;
  }, [content, cursorState, findGlobalWordIndex]);

  const updateCursor = useCallback((position, shouldStartSelection = false) => {
    setCursorState(prev => {
      let newState;
      if (shouldStartSelection) {
        // Starting a new selection
        newState = {
          cursor: position,
          selection: {
            isSelecting: true,
            anchor: position,
            focus: position,
            isCollapsed: true
          }
        };
      } else if (prev.selection.isSelecting) {
        // Extending or updating selection focus
        newState = {
          cursor: position,
          selection: {
            ...prev.selection,
            focus: position,
            isCollapsed: false
          }
        };
      } else {
        // Just moving the cursor, no selection
        newState = {
          cursor: position,
          selection: {
            isSelecting: false,
            anchor: null,
            focus: null,
            isCollapsed: true
          }
        };
      }

      return newState;
    });

    // Update global context cursor
    updateCursorPosition(position, shouldStartSelection);
  }, [updateCursorPosition]);

  const endSelection = useCallback(() => {
    setCursorState(prev => ({
      ...prev,
      selection: {
        ...prev.selection,
        isSelecting: false
      }
    }));
  }, []);

  const clearSelection = useCallback(() => {
    setCursorState(prev => ({
      cursor: prev.cursor,
      selection: {
        isSelecting: false,
        anchor: null,
        focus: null,
        isCollapsed: true
      }
    }));
  }, []);

  const getSelectedContent = useCallback(() => {
    const { selection } = cursorState;
    if (!selection.anchor || !selection.focus || selection.isCollapsed) return null;

    const selectedSegments = [];
    for (const segment of content) {
      const selectedWords = segment.words.filter(word => isWordInSelection(segment.id, word.id));
      if (selectedWords.length > 0) {
        selectedSegments.push({
          ...segment,
          words: selectedWords
        });
      }
    }

    return selectedSegments.length > 0 ? selectedSegments : null;
  }, [content, cursorState, isWordInSelection]);

  const handleKeyboardNavigation = useCallback((event) => {
    const { cursor, selection } = cursorState;
    if (!cursor.segmentId || !cursor.wordId) return;

    const currentSegmentIndex = content.findIndex(s => s.id === cursor.segmentId);
    const currentSegment = content[currentSegmentIndex];
    const currentWordIndex = currentSegment.words.findIndex(w => w.id === cursor.wordId);

    let newPosition = null;

    switch (event.key) {
      case 'ArrowLeft': {
        if (currentWordIndex > 0) {
          newPosition = {
            segmentId: cursor.segmentId,
            wordId: currentSegment.words[currentWordIndex - 1].id,
            isStartOfWord: false
          };
        } else if (currentSegmentIndex > 0) {
          const prevSegment = content[currentSegmentIndex - 1];
          const lastWord = prevSegment.words[prevSegment.words.length - 1];
          newPosition = {
            segmentId: prevSegment.id,
            wordId: lastWord.id,
            isStartOfWord: false
          };
        }
        break;
      }

      case 'ArrowRight': {
        if (currentWordIndex < currentSegment.words.length - 1) {
          newPosition = {
            segmentId: cursor.segmentId,
            wordId: currentSegment.words[currentWordIndex + 1].id,
            isStartOfWord: false
          };
        } else if (currentSegmentIndex < content.length - 1) {
          const nextSegment = content[currentSegmentIndex + 1];
          newPosition = {
            segmentId: nextSegment.id,
            wordId: nextSegment.words[0].id,
            isStartOfWord: false
          };
        }
        break;
      }
      default:
        break;
    }

    if (newPosition) {
      updateCursor(newPosition, event.shiftKey && !selection.isCollapsed);
    }
  }, [content, cursorState, updateCursor]);

  return {
    cursor: cursorState.cursor,
    selection: cursorState.selection,
    updateCursor,
    endSelection,
    clearSelection,
    getSelectedContent,
    isWordInSelection,
    handleKeyboardNavigation,
    isCollapsed: cursorState.selection.isCollapsed
  };
};

export default usePapercutCursor;
