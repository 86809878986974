import React from 'react';
import { Box, Button, ButtonGroup } from '@mui/material';
import { 
  AddBox as AddBoxIcon,
  BorderColor as HighlightIcon,
} from '@mui/icons-material';

const TranscriptToolbar = ({ 
  isSelectionActive, 
  onInsertToPapercut,
  onStyleClick,
}) => {
  return (
    <Box sx={{ 
      p: 2, 
      borderTop: 1, 
      borderColor: 'divider',
      display: 'flex',
      gap: 2,
      justifyContent: 'center',
      flexWrap: 'wrap'
    }}>
      <ButtonGroup>
        <Button
          variant="contained"
          onClick={onInsertToPapercut}
          startIcon={<AddBoxIcon />}
          color="primary"
          disabled={!isSelectionActive}
        >
           Papercut
        </Button>
        <Button
          variant="contained"
          onClick={() => onStyleClick('highlight-green')}
          startIcon={<HighlightIcon />}
          disabled={!isSelectionActive}
          sx={{ 
            bgcolor: 'success.main',
            '&:hover': { bgcolor: 'success.dark' }
          }}
        >
          Green Highlight
        </Button>
      </ButtonGroup>
    </Box>
  );
};

export default TranscriptToolbar;