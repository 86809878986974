// hooks/useCursorDebug.js
import { useEffect } from 'react';

export const useCursorDebug = (content, visualCursor, lastCursor, name = '') => {
  useEffect(() => {
    // Only log when there's a potential mismatch
    if (!visualCursor || !lastCursor) return;
    
    if (visualCursor?.segmentId !== lastCursor?.segmentId || 
        visualCursor?.wordId !== lastCursor?.wordId) {
      console.group(`🎯 Cursor Debug [${name}]`);
      
      // Log cursor states
      console.log('Visual Cursor:', {
        segmentId: visualCursor?.segmentId,
        wordId: visualCursor?.wordId,
        isStartOfWord: visualCursor?.isStartOfWord
      });
      
      console.log('Last Cursor:', {
        segmentId: lastCursor?.segmentId,
        wordId: lastCursor?.wordId,
        isStartOfWord: lastCursor?.isStartOfWord
      });

      // Find segments in content
      const visualSegment = content.find(s => s.id === visualCursor?.segmentId);
      const lastSegment = content.find(s => s.id === lastCursor?.segmentId);

      // Log segment details if found
      if (visualSegment) {
        const word = visualSegment.words.find(w => w.id === visualCursor?.wordId);
        console.log('Visual Cursor Location:', {
          speaker: visualSegment.speaker,
          word: word?.text,
          wordIndex: word?.index
        });
      }

      if (lastSegment) {
        const word = lastSegment.words.find(w => w.id === lastCursor?.wordId);
        console.log('Last Cursor Location:', {
          speaker: lastSegment.speaker,
          word: word?.text,
          wordIndex: word?.index
        });
      }

      console.groupEnd();
    }
  }, [content, visualCursor, lastCursor, name]);
};

export default useCursorDebug;