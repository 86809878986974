import { useState, useCallback, useEffect } from 'react';
import { useTranscriptStyling } from '../../contexts/TranscriptStylingContext';

export const useTranscriptSelection = (displayContent) => {
  const [selection, setSelection] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const { getWordStyle } = useTranscriptStyling();

  const isValidTranscriptNode = useCallback((node) => {
    if (!node || !node.dataset) return false;

    const { fileId, segmentIndex, wordIndex, globalIndex } = node.dataset;
    return !!(
      fileId &&
      !isNaN(parseInt(segmentIndex)) &&
      !isNaN(parseInt(wordIndex)) &&
      !isNaN(parseInt(globalIndex))
    );
  }, []);

  const findValidNodesInRange = useCallback((range) => {
    if (!range || !range.commonAncestorContainer) {
      console.warn('Invalid range provided to findValidNodesInRange');
      return [];
    }

    const container = range.commonAncestorContainer.nodeType === Node.TEXT_NODE 
      ? range.commonAncestorContainer.parentElement
      : range.commonAncestorContainer;

    if (isValidTranscriptNode(container)) {
      return [container];
    }

    const nodes = [];
    const walker = document.createTreeWalker(
      range.commonAncestorContainer,
      NodeFilter.SHOW_ELEMENT | NodeFilter.SHOW_TEXT,
      {
        acceptNode: (node) => {
          const elementToCheck = node.nodeType === Node.TEXT_NODE ? node.parentElement : node;
          return isValidTranscriptNode(elementToCheck) ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
        }
      }
    );

    let currentNode = walker.currentNode;
    while (currentNode) {
      const elementNode = currentNode.nodeType === Node.TEXT_NODE ? currentNode.parentElement : currentNode;
      if (range.intersectsNode(currentNode) && isValidTranscriptNode(elementNode)) {
        nodes.push(elementNode);
      }
      currentNode = walker.nextNode();
    }

    return nodes;
  }, [isValidTranscriptNode]);

  const handleSelectionChange = useCallback(() => {
    const selectionObj = window.getSelection();
    if (!selectionObj || selectionObj.rangeCount === 0) {
      setSelection(null);
      setIsActive(false);
      return;
    }

    const range = selectionObj.getRangeAt(0);
    if (!range) {
      setSelection(null);
      setIsActive(false);
      return;
    }

    const selectedText = range.toString().trim();
    if (!selectedText) {
      setSelection(null);
      setIsActive(false);
      return;
    }

    const validNodes = findValidNodesInRange(range);
    if (validNodes.length > 0) {
      const startElement = validNodes[0];
      const endElement = validNodes[validNodes.length - 1];

      if (isValidTranscriptNode(startElement)) {
        const selectionPoint = {
          fileId: startElement.dataset.fileId,
          segmentIndex: parseInt(startElement.dataset.segmentIndex),
          wordIndex: parseInt(startElement.dataset.wordIndex),
          globalIndex: parseInt(startElement.dataset.globalIndex),
        };

        const newSelection = {
          text: selectedText,
          start: selectionPoint,
          end: validNodes.length === 1 ? selectionPoint : {
            fileId: endElement.dataset.fileId,
            segmentIndex: parseInt(endElement.dataset.segmentIndex),
            wordIndex: parseInt(endElement.dataset.wordIndex),
            globalIndex: parseInt(endElement.dataset.globalIndex),
          },
        };
        
        setSelection(newSelection);
        setIsActive(true);
      } else {
        setSelection(null);
        setIsActive(false);
      }
    } else {
      setSelection(null);
      setIsActive(false);
    }
  }, [findValidNodesInRange, isValidTranscriptNode]);

  const getSelectedContent = useCallback((forDeletion = false) => {
    if (!selection) return [];
  
    const { start, end } = selection;
  
    if (!start || !end || !start.fileId || !end.fileId) {
      console.warn('Invalid selection boundaries:', selection);
      return [];
    }
  
    const selectedContent = [];
    for (const file of displayContent) {
      if (file.fileId !== start.fileId && file.fileId !== end.fileId) continue;
  
      for (const group of file.groupedSegments) {
        for (const segment of group) {
          const selectedWords = segment.words.filter((word) => {
            const isInRange =
              (file.fileId === start.fileId ? word.globalIndex >= start.globalIndex : true) &&
              (file.fileId === end.fileId ? word.globalIndex <= end.globalIndex : true);
  
            if (forDeletion) return isInRange;
  
            const style = getWordStyle(word.id);
            return isInRange && style !== 'strikethrough';
          });
  
          if (selectedWords.length > 0) {
            selectedContent.push({
              fileId: file.fileId,
              fileName: file.fileName,
              speaker: segment.speaker,
              words: selectedWords,
              startTime: selectedWords[0].start,
              endTime: selectedWords[selectedWords.length - 1].end,
              globalIndex: segment.globalIndex,
            });
          }
        }
      }
    }
  
    return selectedContent;
  }, [selection, displayContent, getWordStyle]);
  

  useEffect(() => {
    document.addEventListener('selectionchange', handleSelectionChange);
    return () => {
      document.removeEventListener('selectionchange', handleSelectionChange);
    };
  }, [handleSelectionChange]);

  const clearSelection = useCallback(() => {
    window.getSelection().removeAllRanges();
    setSelection(null);
    setIsActive(false);
  }, []);

  return {
    selection,
    isActive,
    clearSelection,
    getSelectedContent,
    handleSelectionChange,
  };
};
