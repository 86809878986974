import React, { useEffect, useRef, useCallback } from 'react';
import { Box, Typography} from '@mui/material';
import { useSpeakerColors } from '../../../contexts/SpeakerColorContext';
import { useTranscriptStyling } from '../../../contexts/TranscriptStylingContext';
import { useTheme } from '@mui/material/styles';
import { PAUSE_INDICATOR_THRESHOLD } from '../../../hooks/useTranscript/useTranscriptProcessing';



const greenColors = {
  light: 'hsl(137.508, 65%, 60%)',
  dark: 'hsl(137.508, 65%, 45%)',
  textLight: 'hsl(137.508, 65%, 95%)',
  textDark: 'hsl(137.508, 65%, 15%)'
};

const isChinese = char => {
  const code = char.charCodeAt(0);
  return code >= 0x4E00 && code <= 0x9FFF;
};

const TranscriptContent = ({ displayContent, onSelectionChange, highlightedWord = null, selection, getSelectedContent, ...otherProps }) => {
  const contentRef = useRef(null);
  const theme = useTheme();
  const { getSpeakerColor } = useSpeakerColors();
  const { getWordStyle } = useTranscriptStyling();

  const getStyleProps = (style) => {
    const isDarkMode = theme.palette.mode === 'dark';
  
    switch (style) {
      case 'highlight-green':
        return {
          bgcolor: isDarkMode ? greenColors.dark : greenColors.light,
          color: isDarkMode ? greenColors.textDark : greenColors.textDark,
        };
      case 'strikethrough':
        return {
          textDecoration: 'line-through',
          opacity: 0.7,
        };
      default:
        return {};
    }
  };

  const handleDragStart = useCallback((e) => {
    if (!selection) {
      e.preventDefault();
      return;
    }
    const selectedContent = getSelectedContent();
    if (selectedContent && selectedContent.length > 0) {
      e.dataTransfer.setData('application/transcript-selection', JSON.stringify(selectedContent));
      e.dataTransfer.effectAllowed = 'copy';
    } else {
      e.preventDefault();
    }
  }, [getSelectedContent, selection]);

  useEffect(() => {
    const node = contentRef.current;
  
    const handleMouseUp = () => {
      const selection = window.getSelection();
      if (selection && node && node.contains(selection.anchorNode)) {
        const selectedText = selection.toString();
        if (selectedText) {
          onSelectionChange(selectedText);
        } else {
          onSelectionChange(null);
        }
      }
    };
  
    document.addEventListener('mouseup', handleMouseUp);
    if (node) {
      node.addEventListener('dragstart', handleDragStart);
    }
  
    return () => {
      document.removeEventListener('mouseup', handleMouseUp);
      if (node) {
        node.removeEventListener('dragstart', handleDragStart);
      }
    };
  }, [onSelectionChange, handleDragStart]);
  
  const renderWord = (word, fileId) => {
    const style = getWordStyle(word.id);
    const isPause = word.word === '(...)';
    
    const isChineseChar = word.word.length === 1 && isChinese(word.word);
    
    const nextSibling = word.nextSibling;
    const shouldRemoveSpace = isChineseChar && nextSibling && isChinese(nextSibling.word);
    
    return (
      <Typography
        key={word.id}
        component="span"
        variant="body2"
        ref={
          highlightedWord && 
          word.id === highlightedWord.id ? 
          (el) => {
            if (el) {
              el.scrollIntoView({ 
                behavior: 'smooth', 
                block: 'center' 
              });
            }
          } : 
          null
        }
        data-word-id={word.id}
        data-global-index={word.globalIndex}
        data-file-id={fileId}
        data-segment-index={word.position.segment}
        data-word-index={word.position.word}
        sx={{
          display: 'inline',
          px: '1px',
          py: 0.25,
          borderRadius: 1,
          color: isPause ? 'text.disabled' : 'inherit',
          fontStyle: isPause ? 'italic' : 'normal',
          bgcolor: highlightedWord && word.id === highlightedWord.id ? 'action.selected' : 'transparent',
          '&:hover': {
            bgcolor: 'action.hover'
          },
          ...getStyleProps(style),
          letterSpacing: isChineseChar ? '0' : 'inherit',
          marginRight: shouldRemoveSpace ? '0' : undefined,
          '& + span': {
            marginLeft: isChineseChar ? '0' : undefined,
          }
        }}
      >
        {word.word}{!shouldRemoveSpace && ' '}
      </Typography>
    );
  };

  const renderSegments = (segments, fileId) => (
    <Box
      component="span" 
      sx={{
        display: 'inline',
        lineHeight: 1.7,
        '& > span': {
          whiteSpace: 'normal',
          wordWrap: 'break-word'
        }
      }}
    >
      {segments.map((segment, index) => (
        <React.Fragment key={segment.globalIndex}>
          {segment.words.map((word, wordIndex) => {
            const nextWord = segment.words[wordIndex + 1];
            return renderWord({
              ...word,
              nextSibling: nextWord
            }, fileId);
          })}
        </React.Fragment>
      ))}
    </Box>
  );

  const renderGroup = (group, groupIndex, fileId) => {
    const speakerColor = getSpeakerColor(group[0].speaker);
    
    const subgroups = [];
    let currentSubgroup = [];
    
    group.forEach((segment, index) => {
      if (index === 0) {
        currentSubgroup.push(segment);
      } else {
        const prevSegment = group[index - 1];
        const timeGap = segment.startTime - prevSegment.endTime;
        
        if (timeGap > PAUSE_INDICATOR_THRESHOLD) {
          if (currentSubgroup.length > 0) {
            subgroups.push(currentSubgroup);
          }
          currentSubgroup = [segment];
        } else {
          currentSubgroup.push(segment);
        }
      }
    });
    
    if (currentSubgroup.length > 0) {
      subgroups.push(currentSubgroup);
    }
  
    return (
      <Box 
        key={`${fileId}-group-${groupIndex}`}
        sx={{ 
          mb: 3,
          borderLeft: 3,
          borderColor: speakerColor.colors.edgeLine,
          pl: 2
        }}
      >
        <Typography 
          variant="subtitle2"
          sx={{ 
            color: 'primary.main',
            textTransform: 'uppercase',
            mb: 1
          }}
        >
          {group[0].speaker}
        </Typography>
    
        <Box
          sx={{
            '& > span': {
              display: 'inline'
            }
          }}
        >
          {subgroups.map((subgroup, subgroupIndex) => (
            <React.Fragment key={`subgroup-${subgroupIndex}`}>
              {renderSegments(subgroup, fileId)}
            </React.Fragment>
          ))}
        </Box>
      </Box>
    );
  };

  return (
    <Box
      ref={contentRef}
      draggable={!!selection}
      onDragStart={handleDragStart}
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        overflow: 'hidden'
      }}
      onMouseUp={onSelectionChange}
      {...otherProps}
    >
      {displayContent.length === 0 ? (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}
        >
          <Typography 
            variant="body1" 
            sx={{ 
              fontStyle: 'italic',
              textAlign: 'center',
              color: 'text.disabled',
            }}
          >
            Select your transcripts to show here
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            padding: 2,
            userSelect: 'text',
            fontSize: '1rem',
            color: 'text.primary',
            '& p': {
              fontSize: 'inherit',
              color: 'inherit'
            },
            flex: 1,
            overflowY: 'auto'
          }}
        >
          {displayContent.map(file => (
            <Box key={file.fileId}>
              {file.groupedSegments.map((group, groupIndex) => 
                renderGroup(group, groupIndex, file.fileId)
              )}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default TranscriptContent;