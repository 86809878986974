import React, { useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import LoginPage from '../Pages/LoginPage';
import { FileSystemProvider } from '../../contexts/FileSystemContext';
import { SpeakerColorProvider } from '../../contexts/SpeakerColorContext';
import { PapercutProvider } from '../../contexts/PapercutContext';
import { PapercutHistoryProvider } from '../../contexts/PapercutHistoryContext';
import { TranscriptStylingProvider } from '../../contexts/TranscriptStylingContext';
import { ClipboardProvider } from '../../contexts/ClipboardContext';
import MainLayout from './MainLayout';

const ProtectedApp = (props) => {
  const { isAuthenticated, login } = useAuth();

  useEffect(() => {
    const verifyToken = async () => {
      const storedToken = localStorage.getItem('token');

      // If we have a token but not authenticated, attempt to verify
      if (storedToken && !isAuthenticated) {
        try {
          const response = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/verify`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${storedToken}`
            },
          });

          if (response.ok) {
            // If token is valid, get the user from the response
            const data = await response.json();
            if (data.user) {
              login(data.user);
            } else {
              // If no user data returned, clear storage
              localStorage.removeItem('token');
              localStorage.removeItem('user');
            }
          } else {
            // Token invalid, clear storage
            localStorage.removeItem('token');
            localStorage.removeItem('user');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          localStorage.removeItem('token');
          localStorage.removeItem('user');
        }
      }
    };

    verifyToken();
  }, [isAuthenticated, login]);

  if (!isAuthenticated) {
    return <LoginPage />;
  }

  return (
    <FileSystemProvider onFileUpload={props.onFileUpload}>
      <SpeakerColorProvider>
        <PapercutHistoryProvider>
          <PapercutProvider>
            <TranscriptStylingProvider>
              <ClipboardProvider>
                <MainLayout {...props}>
                  {props.children}
                </MainLayout>
              </ClipboardProvider>
            </TranscriptStylingProvider>
          </PapercutProvider>
        </PapercutHistoryProvider>
      </SpeakerColorProvider>
    </FileSystemProvider>
  );
};

export default ProtectedApp;
