import React from 'react';
import { Box, Typography } from '@mui/material';
import  WordMetadata  from './WordMetadata';

export const MetadataPanel = ({ hoveredWord, content }) => (
  <Box
    sx={{
      position: 'sticky',
      bottom: 0,
      left: 0,
      right: 0,
      width: '100%',
      borderTop: 1,
      borderColor: 'divider',
      bgcolor: 'background.paper',
      zIndex: 1000,
      height: '100px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow: 'hidden'
    }}
  >
    {hoveredWord ? (
      <WordMetadata
        word={content
          .find(s => s.id === hoveredWord.segmentId)
          ?.words.find(w => w.id === hoveredWord.wordId)}
        segment={content.find(s => s.id === hoveredWord.segmentId)}
        segmentIndex={content.findIndex(s => s.id === hoveredWord.segmentId)}
        wordIndex={content
          .find(s => s.id === hoveredWord.segmentId)
          ?.words.findIndex(w => w.id === hoveredWord.wordId)}
        fileId={content
          .find(s => s.id === hoveredWord.segmentId)
          ?.sourceReference?.fileId}
      />
    ) : (
      <Typography variant="body2">No word hovered</Typography>
    )}
  </Box>
);