import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { 
  Box, 
  Card, 
  CircularProgress,
  useTheme
} from '@mui/material';
import { useFileSystem } from '../../../contexts/FileSystemContext';
import { useTranscript } from '../../../hooks/useTranscript';
import { usePapercuts } from '../../../contexts/PapercutContext';
import { usePapercutActions } from '../../../hooks/usePapercut/usePapercutActions';
import { useTranscriptSelection } from '../../../hooks/useTranscript/useTranscriptSelection';
import { useTranscriptStyling } from '../../../contexts/TranscriptStylingContext';
import { useClipboard } from '../../../hooks/useClipboard';
import TranscriptToolbar from './TranscriptToolbar';
import TranscriptContent from './TranscriptContent';
import FileCount from './FileCount';
import useProcessTranscripts from '../../../hooks/useTranscript/useTranscriptProcessing';
import { usePapercutHistory } from '../../../hooks/usePapercut/usePapercutHistory';
import { CopySnackbar } from '../../../components/common/CopySnackbar';  // Add this

const TranscriptViewer = () => {
  const theme = useTheme();
  const { selectedItems, getTranscriptData, files } = useFileSystem();
  const { activeTab } = usePapercuts();
  const { insertToPapercut } = usePapercutActions();
  const { highlightedWord } = useTranscript();
  const { addStyle, removeStyle, getWordStyle } = useTranscriptStyling();
  const { pushState, undo, redo, canUndo, canRedo } = usePapercutHistory();
  const [copyFeedback, setCopyFeedback] = useState(false);
  const [lastCopiedContent, setLastCopiedContent] = useState(null);

  // Undo/Redo keyboard handling
  useEffect(() => {
    const handleKeyDown = (event) => {
      if ((event.ctrlKey || event.metaKey) && event.key === 'z') {
        event.preventDefault();
        if (event.shiftKey && canRedo) {
          redo();
        } else if (canUndo) {
          undo();
        }
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [canUndo, canRedo, undo, redo]);
  
  const transcripts = useMemo(() => {
    const selectedFileIds = selectedItems.filter(id => 
      files[id] && files[id].type !== 'folder'
    );

    const getFilePath = (fileId) => {
      const result = [];
      let currentId = fileId;
      while (currentId) {
        const file = files[currentId];
        if (!file) break;
        result.unshift({
          id: file.id,
          name: file.name,
          order: file.order
        });
        currentId = file.parentId;
      }
      return result;
    };

    const sortedFileIds = selectedFileIds.sort((a, b) => {
      const pathA = getFilePath(a);
      const pathB = getFilePath(b);
      
      for (let i = 0; i < Math.min(pathA.length, pathB.length); i++) {
        if (pathA[i].id !== pathB[i].id) {
          return pathA[i].order - pathB[i].order;
        }
      }
      return pathA.length - pathB.length;
    });

    return getTranscriptData(sortedFileIds);
  }, [selectedItems, getTranscriptData, files]);

  const [displayContent, isProcessing] = useProcessTranscripts(transcripts);
  
  const { 
    selection, 
    clearSelection, 
    getSelectedContent, 
    handleSelectionChange 
  } = useTranscriptSelection(displayContent);

  const { copy, paste } = useClipboard('transcript');

// Copy handler
useEffect(() => {
  const handleKeyboardCopy = async (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'c' && selection) {
      event.preventDefault();
      const selectedContent = getSelectedContent();
      if (selectedContent) {
        // Store content before copying
        setLastCopiedContent(selectedContent);
        
        // Perform copy
        const success = copy(selectedContent);
        
        if (success) {
          // Show feedback
          setCopyFeedback(true);
          
          // Add brief delay before clearing
          setTimeout(() => {
            clearSelection();
            setLastCopiedContent(null);
          }, 300);
        }
      }
    }
  };

  document.addEventListener('keydown', handleKeyboardCopy);
  return () => {
    document.removeEventListener('keydown', handleKeyboardCopy);
  };
}, [copy, selection, getSelectedContent, clearSelection]);


// Paste handler
useEffect(() => {
  const handleKeyboardPaste = async (event) => {
    if ((event.ctrlKey || event.metaKey) && event.key === 'v') {
      console.log('Paste operation initiated');
      const success = paste(({ content, source }) => {
        console.log('Pasting content:', { content, source, activeTab });
        if (Array.isArray(content) && content.length > 0) {
          insertToPapercut(activeTab, content);
        } else {
          console.warn('Invalid content format for pasting');
        }
      });

      if (success) {
        console.log('Paste operation successful');
        event.preventDefault();
      }
    }
  };

  document.addEventListener('keydown', handleKeyboardPaste);
  return () => {
    document.removeEventListener('keydown', handleKeyboardPaste);
  };
}, [paste, activeTab, insertToPapercut]);


  const handleStyleClick = (style) => {
    if (selection) {
      const selectedContent = getSelectedContent();
      // Check if any selected words already have the style
      const hasStyle = selectedContent.some(content => 
        content.words.some(word => getWordStyle(word.id) === style)
      );
  
      if (hasStyle) {
        removeStyle(selectedContent);
        pushState({ type: 'REMOVE_STYLE', content: selectedContent });
      } else {
        addStyle(selectedContent, style);
        pushState({ type: 'ADD_STYLE', content: selectedContent, style });
      }
      clearSelection();
    }
  };
  
  useEffect(() => {
    const handleDelete = (event) => {
      if (!selection) return;
  
      if (event.key === 'Delete' || event.key === 'Backspace') {
        event.preventDefault();
        event.stopPropagation();
  
        console.log("Delete key pressed, fetching selection...");
        const selectedContent = getSelectedContent(true); // Pass 'true' for deletion
  
        console.log('Selected content for deletion:', selectedContent);
  
        if (selectedContent.length === 0) {
          console.warn('Nothing selected for deletion.');
          return;
        }
  
        // Check if the selection contains any words with strikethrough
        const hasStrikethrough = selectedContent.some(content =>
          content.words.some(word => getWordStyle(word.id) === 'strikethrough')
        );
  
        console.log('Has strikethrough words?', hasStrikethrough);
  
        if (hasStrikethrough) {
          console.log('Removing strikethrough style...');
          addStyle(selectedContent, ''); // Remove style
          pushState({ type: 'REMOVE_STYLE', content: selectedContent });
        } else {
          console.log('Applying strikethrough style...');
          addStyle(selectedContent, 'strikethrough');
          pushState({ type: 'ADD_STYLE', content: selectedContent, style: 'strikethrough' });
        }
  
        clearSelection();
      }
    };
  
    document.addEventListener('keydown', handleDelete, true);
    return () => {
      document.removeEventListener('keydown', handleDelete, true);
    };
  }, [selection, getSelectedContent, addStyle, pushState, clearSelection, getWordStyle]);
  
 
  const handleInsertToPapercut = () => {
    if (selection) {
      const selectedContent = getSelectedContent();
      insertToPapercut(activeTab, selectedContent);
      clearSelection();
    }
  };

  const getHighlightStyle = useCallback((segmentId, wordId) => {
    if (lastCopiedContent && lastCopiedContent.some(segment => 
      segment.id === segmentId && 
      segment.words.some(word => word.id === wordId)
    )) {
      return {
        backgroundColor: theme.palette.primary.main + (theme.palette.mode === 'dark' ? '80' : '40'),  // 50% or 25% opacity
        transition: 'background-color 0.3s ease'
      };
    }
    return {};
  }, [lastCopiedContent, theme]);
  
  return (
    <Card sx={{ 
      height: '100%', 
      display: 'flex', 
      flexDirection: 'column',
      bgcolor: 'background.paper'
    }} tabIndex={0}>
      <FileCount count={transcripts.length} />
      
      <Box sx={{ 
        flexGrow: 1, 
        display: 'flex', 
        flexDirection: 'column', 
        overflow: 'hidden'
      }}>
        <Box sx={{ 
          flexGrow: 1, 
          overflowY: 'auto', 
          maxWidth: '900px', 
          margin: '0 auto'
        }}>
          {isProcessing ? (
            <Box sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center', 
              height: '100%' 
            }}>
              <CircularProgress />
            </Box>
          ) : (
            <TranscriptContent
              displayContent={displayContent}
              onSelectionChange={handleSelectionChange}
              highlightedWord={highlightedWord}
              selection={selection} 
              getSelectedContent={getSelectedContent}
              getHighlightStyle={getHighlightStyle} 
            />
          )}
        </Box>
        <TranscriptToolbar 
          isSelectionActive={!!selection}
          onInsertToPapercut={handleInsertToPapercut}
          onStyleClick={handleStyleClick}
        />
      </Box>
      <CopySnackbar 
        open={copyFeedback} 
        onClose={() => setCopyFeedback(false)} 
      />
    </Card>
  );
};

export default TranscriptViewer;